import Activities from './activities/activities'
import Inbox from './inbox/inbox'
import Gaming from '../gaming/gaming'

import Communication from '../../communication/communication'
import Sdk from '../../sdk'
import conf from './notification_config'
import utils from '../../utils'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'

let instance = null
let observable = null // init the observable.

/**
 * Notification class responsible of all notification functionality and manage the state of server notifications.
 * @class Notification
 * @private
 */
class Notification implements Initiable<Notification> {
	loaded: boolean
	config: any
	/** Expose the notification state. */
	state: Rxjs_subject<any>
	current: any
	activities: Activities
	inbox: Inbox


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.notification, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.notification] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @param {Class} [activities = Activities] - Activities module.
	 * @param {Class} [inbox = Inbox] - Inbox module.
	 * @returns {Promise<Array>} Child modules
	 */
	init(config: any = {}, defaults = conf.defaults.notification, props = conf.configProps, activities = Activities, inbox = Inbox) {
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props)
		// initialize rxjs subject as the module state.
		if (!observable || config.test) {
			observable = new Rxjs_subject<any>()
			this.state = observable
		} else {
			this.state = observable
		}

		utils.moduleInitializer(this, 'activities', activities, this.config)
		utils.moduleInitializer(this, 'inbox', inbox, this.config)

		this.sync()
		this.loaded = true
		return this
	}

	/**
	 * Sync the state of notification with his child's modules.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} Current state.
	 */
	sync() {
		this.current = {activities: [], inbox: []}
		const stateUpdate = (subject, subjectName) => {
			subject.subscribe((newState) => {
				this.current = Object.assign(this.current, {[subjectName]: newState})
				this.state.next(this.current)
			})
		}
		stateUpdate(this.activities.state, 'activities')
		stateUpdate(this.inbox.state, 'inbox')

		return this.current
	}

	/**
	 * Fetch notifications from server(using JSONP)
	 * @version 1.0.0
	 * @async
	 * @private
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Array>} List of notifications
	 */
	get_notifications_data(sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])
		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/unread_inbox/${sdk.config.player.id}`
		const params = this.paramsBuilder()
		const config = {
			requestType: 'http',
			method: 'get'
		} as const
		return communication.request(url, params, config)
	}

	/**
	 * Responsible to create params object with defaults and custom parameters
	 * @version 1.0.0
	 * @private
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} Params object.
	 */
	paramsBuilder(sdk = new Sdk) {
		// Check if the options.type is valid.
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk},
		])
		// Build the request params.
		const params = {
			app: sdk.config.api_key
		}

		// Verify if client_token and access_token is exist
		// If they exist -> add them as params to the request.
		if (sdk.config.client_token && sdk.config.access_token) {
			params['client_token'] = sdk.config['client_token']
			params['access_token'] = sdk.config['access_token']
		}

		return params
	}

	/**
	 * Find specific setting set from settings list.
	 * Example: level in level collection, badge and action in action_settings.
	 * @version 1.0.0
	 * @private
	 * @param {String} name - Setting name.
	 * @param {Array} [settings = []] - settings list.
	 * @returns {Object} Setting set.
	 */
	find_settings_by_name(name, settings = []) {
		// Check if the name and settings is valid.
		utils.validateDependencies([
			{name: 'name', type: 'String', val: name},
			{name: 'settings', type: 'Array', val: settings}
		])

		return settings.filter((setting) => {
			return setting.name === name
		})[0]
	}

	/**
	 * notify_activity does not only handle actions directly by their name.
	 * It acts as 2 things at once:
	 * It is being called from notification_fetch which is what gives it the action name (a.k.a level_up and achieve).
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [activities = {}] - Activities data.
	 * @param {Object} [activitiesIncludeLevelAndBadge = false] - If the activities include level and badge.
	 * @param {Object} [gamingModule = {}] - Gaming module.
	 */
	notify_activity(activities = {}, activitiesIncludeLevelAndBadge = false, gamingModule = Gaming) {
		// Check if the options and activitiesIncludeLevelAndBadge is valid.
		utils.validateDependencies([
			{name: 'activities', type: 'Object', val: activities},
			{name: 'activitiesIncludeLevelAndBadge', type: 'Boolean', val: activitiesIncludeLevelAndBadge},
			{name: 'gamingModule', type: 'Function', val: gamingModule},
		])

		const gaming = new gamingModule
		return Promise.all(gaming.processActivity(activities)).then((processedActivities) => {
			return processedActivities.map(activity => this.trigger_notification(activity, activitiesIncludeLevelAndBadge))
		})
	}

	/**
	 * Trigger event of 'notification' with the notification we get from server as notification data.
	 * @version 1.0.0
	 * @private
	 * @param {Object} params - Parameters to emit.
	 * @param {Boolean} [activitiesIncludeLevelAndBadge = false] - If the activities include badges and levels.
	 * @param {Boolean} [showLevelAnimationTogetherWithBadgeAnimation = this.config.showLevelAnimationTogetherWithBadgeAnimation] - If configured to show level animation together with badge animation.
	 * @param trigger - Implementation in the embed of event dispatcher.
	 */
	trigger_notification(params, activitiesIncludeLevelAndBadge = false, showLevelAnimationTogetherWithBadgeAnimation = this.config.showLevelAnimationTogetherWithBadgeAnimation, trigger = (utils.get_environment_global_var())['captain'].trigger || utils.trigger) {
		// Check if the params, activitiesIncludeLevelAndBadge, showLevelAnimationTogetherWithBadgeAnimation and trigger is valid.
		utils.validateDependencies([
			{name: 'params', type: 'Object', val: params},
			{name: 'activitiesIncludeLevelAndBadge', type: 'Boolean', val: activitiesIncludeLevelAndBadge},
			{name: 'showLevelAnimationTogetherWithBadgeAnimation', type: 'Boolean', val: showLevelAnimationTogetherWithBadgeAnimation},
			{name: 'trigger', type: 'Function', val: trigger},
		])

		if(!(params.type === 'rank' && activitiesIncludeLevelAndBadge && !showLevelAnimationTogetherWithBadgeAnimation))
			return trigger('notification', params)
	}
}

export default Notification
