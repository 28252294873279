import utils from '../../../utils'
import Sdk from '../../../sdk'
import Communication from '../../../communication/communication'
import conf from './leaderboard_config'
import * as _ from 'lodash'

let instance = null // init the instance.

type leaderboard_types = 'daily' | 'weekly' | 'monthly' | 'all_time' | 'tournament'

export interface LeaderboardResponse {
	code: number,
	data: any[],
	leaderboard: string,
	player_rank: number | null,
	skip: number,
	total: 0
}

export interface LeaderboardRequestState {
	stack: any
	total: any
	next: Leaderboard['next']
	prev: Leaderboard['prev']
	me: Leaderboard['me']
	type: 'daily' | 'weekly' | 'monthly' | 'all_time' | 'tournament',
	skip: number,
	tournament_id?: string
	normalized_tournament_time?: Date
}

/**
 * Leaderboard class responsible of all leaderboard functionality and manage the state of leaderboards.
 * 
 * You can either use the {@link get} method to get leaderboard, and then manage state (how many skipped, next, etc.) on your own -
 * or you can use the leaderboards methods that then create an instance that manages the state for you and you only use {@link next} and {@link prev} method to navigate.
 * Those leaderboard state methods are {@link daily}, {@link weekly}, {@link monthly}, {@link allTime} for time-base leaderboards and {@link tournament} for tournaments leaderboard.  
 * 
 * This is a class reference, find the tutorial here: {@link Tutorial_Leaderboard}
 * 
 * Part of the {@link User} module.
 * @category User
 */
class Leaderboard implements Initiable<Leaderboard> {
	loaded: boolean
	config: any
	index: number
	page: number
	total: number
	type: leaderboard_types
	last: any
	stack: any

	tournament_id: string
	normalized_tournament_time: Date


	/**
	 * Construct the module.
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.leaderboard, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.leaderboard] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config = {}, defaults = conf.defaults.leaderboard, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configurations

		this.setConfig({
			monthly: {index: 0, stack: []},
			weekly: {index: 0, stack: []},
			daily: {index: 0, stack: []},
			all_time: {index: 0, stack: []}
		})

		this.loaded = true

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.user.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.user.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get leaderboard data.
	 * 
	 * This works for both points leaderboard (when `type` is `daily` / `weekly` / `monthly` / `all_time`) and for tournaments (when `type` is `tournament`)
	 * 
	 * Though you can use this method directly, it is better to use the direct methods for the leadearboards as they manage pagination automatically.
	 * Check them out below, at {@link daily}, {@link weekly}, {@link monthly}, {@link allTime}, and {@link tournament}
	 * 
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param type - Leaderboard type. `daily` / `weekly` / `monthly` / `all_time` for points leaderboards, `tournament` for tournaments.
	 * @param mySelf - Get data around my self.
	 * @param limit - Max number of leaderboard rows.
	 * @param skip - Amount of rows to skip from zero.
	 * @param tournament_id - If `type` is `tournament`, provide the tournament_id your'e interested in here.
	 * @param tournament_time - If `type` is `tournament`, provide the tournament_time to get the instance you want.
	 * Relevant to repeatable tournaments, e.g a weekly tournament, provide Date of next week to get the next week tournament leaderboard.
	 * @param communication - Communication instance.
	 * @param sdk - Sdk instance.
	 * @returns {Promise<LeaderboardResponse>} Leaderboard data.
	 * @example
	 * ```
	 * // Get the daily leaderboard
	 * captain.leaderboard.get('daily').then((leaderboardRows) => {
	 * 	// Daily leaderboard rows.
	 * });
	 * 
	 * // Get a tournament leaderboard
	 * captain.leaderboard.get('tournament', myself=false, limit=5, skip=0, tournament_id='618a902425895b6a185ec4d0').then((leaderboardRows) => {
	 * 	// Tournament leaderboard rows.
	 * });
	 * 
	 * // For a weekly repeatable tournament, get the leaderboard of
	 * // last week. If today is 24/11/2022, pass any date of last week, e.g 16/11/2022
	 * captain.leaderboard.get('tournament', myself=false, limit=5, skip=0, tournament_id='618a902425895b6a185ec4d0', tournament_time=new Date('2022-11-16')).then((leaderboardRows) => {
	 * 	// Tournament leaderboard rows.
	 * });
	 * ```
	 */
	get(type: leaderboard_types, mySelf: boolean = false, limit: number = 5, skip: number = 0, tournament_id: string = null, tournament_time = new Date(), communication = new Communication, sdk = new Sdk): Promise<LeaderboardResponse> {
		utils.validateDependencies([
			{name: 'type', type: 'String', val: type},
			{name: 'mySelf', type: 'Boolean', val: mySelf},
			{name: 'limit', type: 'Number', val: limit},
			{name: 'skip', type: 'Number', val: skip},
			{name: 'communication', type: 'Object', val: communication},
			{name: 'sdk', type: 'Object', val: sdk},
		])

		const validateLimit = (limit < 5) ? 5 : limit
		let params: any = {
			limit: validateLimit,
			skip
		}

		let url;
		if (type ==='tournament') {
			url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.api_key}/tournaments/${tournament_id}/leaderboard`
			params = {
				tournament_time: this.normalizeDate(tournament_time).toISOString(),
				...params
			}
		} else {
			url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.api_key}/leaderboards/${type}_ranking`
		}
		
		if(mySelf) {
			params = {
				player_id: sdk.config.player.id,
				...params
			}
		}
		const config = {
			requestType: 'http' as const,
			method: 'get'
		}
		const cb = res => res
		return communication.request(url, params, config, cb)
			.then((res) => {
				if(validateLimit !== limit)
					res.data = res.data.slice(0, limit)
				return res
			})
	}

	/**
	 * Internal method to normalize dates in the seconds, minutes, and hours to 12:00:00.
	 * We do this because we use the date passed for tournaments as its "identifier" for next requests -
	 * And since "daily" is the "fastest" recurring - it doesn't matter if we normalize, and thus
	 * prevent re-identifieying as different request for request with dates a few minutes later.
	 * @private
	 * @param date the date to normalize
	 */
	normalizeDate(date: Date) {
		date.setSeconds(0)
		date.setMilliseconds(0)
		date.setMinutes(0)
		date.setHours(12)
		return date
	}

	/**
	 * Merge between leaderboard local stack and new data.
	 * @version 1.0.0
	 * @private
	 * @param stack - Leaderboard local stack of rows.
	 * @param type - Leaderboard type name.
	 * @param tournament_id - If leaderboard type is 'tournament', then this is applicable
	 * @param normalized_tournament_time - If leaderboard type is 'tournament', then this is applicable
	 * @returns {Array} Updated local stack.
	 */
	updateStack(stack: any[], type: leaderboard_types, tournament_id: string, normalized_tournament_time: Date) {
		utils.validateDependencies([
			{name: 'stack', type: 'Array', val: stack},
			{name: 'type', type: 'String', val: type},
		])

		let leaderboard_name
		if (type === 'tournament') {
			leaderboard_name = `tournament_${tournament_id}_${normalized_tournament_time}`
		} else {
			leaderboard_name = type
		}

		const current = this.getConfig()[leaderboard_name]
		current.stack = _.uniqBy([...current.stack, ...stack], `${type}_position`)
		this.setConfig({[leaderboard_name]: current})

		return current.stack
	}

	/**
	 * Update the leaderboard local total state with new total.
	 * @version 1.0.0
	 * @private
	 * @param total - Leaderboard total amount of rows.
	 * @param type - Leaderboard type name.
	 * @param tournament_id - If leaderboard type is 'tournament', then this is applicable
	 * @param normalized_tournament_time - If leaderboard type is 'tournament', then this is applicable
	 * @returns {number} Updated total number.
	 */
	updateTotal(total: number, type: leaderboard_types, tournament_id: string, normalized_tournament_time: Date): number {
		utils.validateDependencies([
			{name: 'total', type: 'Number', val: total},
			{name: 'type', type: 'String', val: type},
		])

		let leaderboard_name
		if (type === 'tournament') {
			leaderboard_name = `tournament_${tournament_id}_${normalized_tournament_time}`
		} else {
			leaderboard_name = type
		}

		const current = this.getConfig()[leaderboard_name]
		current.total = total
		this.setConfig({[leaderboard_name]: current})
		return current.total
	}

	/**
	 * Calculate the skip by the last move and current move.
	 * @version 1.0.0
	 * @private
	 * @param {Number} skip - Amount of rows to skip from zero.
	 * @param {Object} last - Last pagination move.
	 * @returns {Number} Calculated skip number.
	 */
	switchDirection(skip, last, direction) {
		utils.validateDependencies([
			{name: 'skip', type: 'Number', val: skip},
			{name: 'last', type: ['Object', 'Undefined'], val: last},
			{name: 'direction', type: 'String', val: direction},
		])

		if(last && last.direction !== 'next' && direction === 'next')
			skip += last.stack.length
		else if(last && last.direction !== 'prev' && direction === 'prev')
			skip -= last.stack.length
		return skip
	}

	/**
	 * Next is a method inside pagination object, use for get next leaderboard rows.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @requires pagination-object
	 * @param {Number} [limit=5] - Max number of leaderboard rows.
	 * @param {Number} [skip=this.index||0] -  Amount of rows to skip from zero.
	 * @returns {Promise<Array>} Next leaderboard rows.
	 */
	async next(limit = 5, skip = this.index || 0) {
		utils.validateDependencies([
			{name: 'limit', type: 'Number', val: limit},
			{name: 'skip', type: 'Number', val: skip},
		])

		const self = this
		skip = instance.switchDirection(skip, self.last, 'next')
		const start = self.page = skip
		const end = (skip + limit > self.total) ? self.total : skip + limit
		let result = self.stack.slice(start, end)
		result = result.map(val => val).filter(res => res)
		const updateStack = (res) => {
			instance.updateStack.call(instance, self.stack, self.type, self.tournament_id || null, self.normalized_tournament_time || null)
			return res
		}
		const updateTotal = (res) => {
			self.total = res.total
			instance.updateTotal.call(instance, res.total, self.type, self.tournament_id || null, self.normalized_tournament_time || null)
			return res
		}
		const updateLast = (res) => {
			if(res.length)
				this.last = {stack: res, direction: 'next'}
			return res
		}

		let response
		if(result.length === limit) {
			response = Promise.resolve(result)
				.then((res) => {
					self.index = skip + res.length
					return res
				})
		} else {
			response = await instance.get(self.type, false, limit, skip, self.tournament_id || null, self.normalized_tournament_time || null)
			updateTotal(response)
			response.data.map((val) => {
				skip = val[`${self.type}_position`] - 1
				self.stack[skip] = Object.assign({}, val)
				skip++
			})
			self.index = skip
			response = response.data
			updateStack(response)
		}

		updateLast(response)
		return response
	}

	/**
	 * Prev is a method inside pagination object, use for get prev leaderboard rows.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @requires pagination-object
	 * @param {Number} [limit=5] - Max number of leaderboard rows.
	 * @param {Number} [skip=this.index||0] -  Amount of rows to skip from zero.
	 * @returns {Promise<Array>} Prev leaderboard rows.
	 */
	prev(limit = 5, skip = this.index || 0) {
		utils.validateDependencies([
			{name: 'limit', type: 'Number', val: limit},
			{name: 'skip', type: 'Number', val: skip},
		])

		if(skip === 0)
			return Promise.resolve([])
		const self = this
		skip = instance.switchDirection(skip, self.last, 'next')
		let response
		const start = self.page = ((skip - limit) >= 0 ) ? (skip - limit) : 0
		const end = skip
		let result = self.stack.slice(start, end)
		result = result.map(val => val).filter(res => res)
		const updateStack = (res) => {
			instance.updateStack.call(instance, self.stack, self.type)
			return res
		}
		const updateTotal = (res) => {
			self.total = res.total
			instance.updateTotal.call(instance, res.total, self.type, self.tournament_id || null)
			return res
		}
		const updateLast = (res) => {
			if(res.length)
				this.last = {stack: res, direction: 'prev'}
			return res
		}

		if(result.length === limit) {
			response = Promise.resolve(result)
				.then((res) => {
					self.index = skip - res.length
					return res
				})
		} else {
			limit = (limit > self.index) ? self.index : limit
			response = instance.get(self.type, false, limit, start)
				.then(updateTotal)
				.then((res) => {
					res.data
					.sort((v) => v[`${self.type}_position`])
					.map((val) => {
						skip = val[`${self.type}_position`] - 1
						self.stack[skip] = Object.assign({}, val)
						skip--
					})
					self.index = (skip < 0) ? 0 : skip + 1
					return res.data
				})
				.then(updateStack)
		}

		return response
			.then((res) => updateLast(res))
	}

	/**
	 * Me is a method inside pagination object, use for get leaderboard rows near to my self.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @requires pagination-object
	 * @param {Number} [limit=5] - Max number of leaderboard rows.
	 * @param {Number} [skip=this.index||0] -  Amount of rows to skip from zero.
	 * @returns {Promise<Array>} Near to my self leaderboard rows.
	 */
	me(limit = 5, skip = this.index || 0) {
		utils.validateDependencies([
			{name: 'limit', type: 'Number', val: limit},
			{name: 'skip', type: 'Number', val: skip},
		])

		const self = this
		const updateStack = (res) => {
			instance.updateStack.call(instance, self.stack, self.type)
			return res
		}
		const updateLast = (res) => {
			if(res.length)
				this.last = {stack: res, direction: 'next'}
			return res
		}
		let response = instance.get(self.type, true, limit, skip)
			.then((res) => {
				self.total = res.total
				res.data.map((val) => {
					skip = val[`${self.type}_position`] - 1
					self.stack[skip] = Object.assign({}, val)
					skip++
				})
				self.index = skip
				return res.data
			})
			.then(updateStack)
			.then((res) => {
				self.page = res[0][`${self.type}_position`]
				return res
			})


		return response
			.then((res) => updateLast(res))
	}

	/**
	 * Get all time leaderboard pagination object.
	 * @version 1.0.0
	 * @public
	 * @returns {Object} - Pagination object.
	 */
	allTime(): LeaderboardRequestState {
		const current = this.getConfig().all_time
		return {stack: current.stack, total: current.total, next: this.next, prev: this.prev, me: this.me, type: 'all_time', skip: 0}
	}

	/**
	 * Get monthly leaderboard pagination object.
	 * @version 1.0.0
	 * @public
	 * @returns {Object} - Pagination object.
	 */
	monthly(): LeaderboardRequestState {
		const current = this.getConfig().monthly
		return {stack: current.stack, total: current.total, next: this.next, prev: this.prev, me: this.me, type: 'monthly', skip: 0}
	}

	/**
	 * Get weekly leaderboard pagination object.
	 * @version 1.0.0
	 * @public
	 * @returns {Object} - Pagination object.
	 */
	weekly(): LeaderboardRequestState {
		const current = this.getConfig().weekly
		return {stack: current.stack, total: current.total, next: this.next, prev: this.prev, me: this.me, type: 'weekly', skip: 0}
	}

	/**
	 * Get daily leaderboard pagination object.
	 * @version 1.0.0
	 * @public
	 * @returns {Object} - Pagination object.
	 */
	daily(): LeaderboardRequestState {
		const current = this.getConfig().daily
		return {stack: current.stack, total: current.total, next: this.next, prev: this.prev, me: this.me, type: 'daily', skip: 0}
	}

	/**
	 * Set a tournament leaderboard stats
	 * @version 1.0.0
	 * @public
	 * @returns {Object} - Pagination object.
	 */
	tournament(tournament_id: string, tournament_time = new Date()): LeaderboardRequestState {
		const normalized_tournament_time = this.normalizeDate(tournament_time)
		const current_config = this.getConfig()
		if (!(`tournament_${tournament_id}_${normalized_tournament_time}` in current_config)) {
			current_config[`tournament_${tournament_id}_${normalized_tournament_time}`] = {index: 0, stack: []}
			this.setConfig(current_config)
		}
		const current = current_config[`tournament_${tournament_id}_${normalized_tournament_time}`]
		return {stack: current.stack, total: current.total, next: this.next, prev: this.prev, me: this.me, type: 'tournament', skip: 0, tournament_id, normalized_tournament_time}
	}

}

export default Leaderboard
