import conf from './gaming_config'
import utils from '../../utils'
import Sdk from '../../sdk'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'
import Currencies from './currencies/currencies'
import Badges from './badges/badges'
import Tournaments from './tournaments/tournaments'
import Assets from './assets/assets'
import Levels from './levels/levels'
import Tiers from './tiers/tiers'

let instance = null
let observable = null // init the observable.

/**
 * Gaming class responsible of all gaming functionality and manage the state of user gaming.
 * @category Gaming
 * @class Gaming
 */
class Gaming implements Initiable<Gaming> {
	loaded: boolean
	config: any
	/**
	 * State subscriber to updates from any Gaming module (Badges, Assets, Cuurencies, Levels, etc.)
	 */
	state: Rxjs_subject<any>
	private current: any

	levels: Levels
	badges: Badges
	tournaments: Tournaments
	currencies: Currencies
	assets: Assets
	tiers: Tiers

	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.gaming, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} defaults=conf.defaults.gaming - Defaults object.
	 * @param {Array} props=conf.configProps - Valid config properties array.
	 * @returns Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.gaming, props = conf.configProps) {
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props)
		// initialize rxjs subject as the module state.
		if (!observable || config.test) {
			observable = new Rxjs_subject<any>()
			this.state = observable
		} else {
			this.state = observable
		}

		this.initSubModules(this.config)
		this.sync()
		this.loaded = true
		return this
	}

	/**
	 * Init sub modules.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @returns {Array<Promise>} List of promised modules.
	 */
	initSubModules(config = {}, sdk = new Sdk) {
		utils.moduleInitializer<Badges>(this, 'badges', Badges, config)
		utils.moduleInitializer<Levels>(this, 'levels', Levels, config)
		utils.moduleInitializer<Currencies>(this, 'currencies', Currencies, config)
		utils.moduleInitializer<Assets>(this, 'assets', Assets, config)
		utils.moduleInitializer<Tournaments>(this, 'tournaments', Tournaments, config)
		if (sdk.config.is_tiers_enabled === true) {
			utils.moduleInitializer<Tiers>(this, 'tiers', Tiers, config)
		}
	}

	/**
	 * Sync the state of gaming with his child's modules.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} Current state.
	 */
	sync(sdk = new Sdk) {
		this.current = {badges: [], currencies: {}, level: {}, assets: {}, tiers: {}}
		const stateUpdate = (subject, subjectName) => {
			subject.subscribe((newState) => {
				this.current = Object.assign({}, this.current, {[subjectName]: newState})
				this.state.next(this.current)
			})
		}
		stateUpdate(this.badges.state, 'badges')
		stateUpdate(this.tournaments.state, 'tournaments')
		stateUpdate(this.levels.state, 'level')
		stateUpdate(this.currencies.state, 'currencies')
		stateUpdate(this.assets.state, 'assets')
		if (sdk.config.is_tiers_enabled === true) {
			stateUpdate(this.tiers.state, 'tier')
		}
		return this.current
	}

	/**
	 * Responsible for processing every activity.
	 * @version 1.0.0
	 * @async
	 * @private
	 * @param {Object} activities={} - Activities data.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array>} Processed activities.
	 */
	processActivity(activities: any = {}, sdk = new Sdk) {
		// Check if the activities and sdk is valid.
		utils.validateDependencies([
			{name: 'activities', type: 'Object', val: activities},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		const levelProcess = (activity) => {
			return this.levels.update(activity).then((level) => {
				return this.levels.processActivity(level)
			})
		}

		const tierProcess = (activity) => {
			return this.tiers.update(activity).then((tier) => {
				return this.tiers.processActivity(tier)
			})
		}

		const assetProcess = (activity) => {
			return this.assets.update(activity)
		}

		const badgeProcess = (activity, badge_progress) => {
			if (!activity)
				return this.badges.update(undefined, badge_progress).then(() => ({}))

			const id = activity.id
			return this.badges.getBadge(id).then((badge) => {
				return this.badges.update(badge, badge_progress).then((badges) => {
					const updatedBadge = badges.filter(v => v.id === id)[0]
					return this.badges.processActivity(updatedBadge)
				})
			})
		}

		const currencyProcess = (currencies, action, actions = []) => {
			const processActivity = (options) => {
				const params = {
					action: options.setting,
					type: 'points',
					message: false, // will generate random message
					points: currencies.points.amount_received
				}
				return Promise.resolve(params)
			}

			this.currencies.update(currencies)

			if (!action.setting)
				action.setting = actions.find((x) => x.name === action.name)

			return processActivity(action)
		}

		let badges, levels, currencies, assets, tiers
		if(activities.badges) {
			if (!_.isEmpty(activities.badges)) {
				badges = activities.badges.map((badge) => badgeProcess(badge, activities.badge_progress))
			} else if (this.config.sensitive_badge_state) {
				badges = badgeProcess(undefined, activities.badge_progress)
			} else {
				badges = []
			}
		}
		if(activities.levels) {
			levels = activities.levels.map(levelProcess)
		}
		if(activities.tier) {
			tiers = tierProcess(activities.tier)
		}
		if(activities.acquired_assets) {
			assets = activities.acquired_assets.map(assetProcess)
		}
		if(activities.currencies && activities.action) {
			currencies = currencyProcess(activities.currencies, activities.action, sdk.config.action_settings)
		}
		const processed_activities = [badges, levels, currencies, assets]
		if(activities.tier){
			processed_activities.push(tiers)
		}
		return _.flatten(processed_activities)

	}

	/**
	 * Get calculate amount of point left for user to reach some level(Default: next user level)
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} playerId - Player id.
	 * @param {String=} levelId - Id of the require level.
	 * @returns {Promise<Object>} Percent and points left to level.
	 */
	getLeftToReachLevel(playerId, levelId) {
		utils.validateDependencies([
			{name: 'playerId', type: ['String', 'Undefined'], val: playerId},
			{name: 'levelId', type: ['String', 'Undefined'], val: levelId}
		])

		const to_percent = (a, b, acc = 1) => {
			if (b === 0)
				return '0'
			return (a * 100 / b).toFixed(acc)
		}

		let dependencies: Promise<any>[]
		if(levelId)
			dependencies = [this.levels.getLevel(levelId), this.levels.get(playerId), this.currencies.get(playerId)]
		else
			dependencies = [this.levels.getNext(playerId), this.levels.get(playerId), this.currencies.get(playerId)]

		return Promise.all(dependencies).then(([next_level, current_level, currencies]) => {
			if((next_level.points - currencies.points) < 0)
				return {percent: '100', points: 0}
			const points = next_level.points - currencies.points
			const a = currencies.points - current_level.points
			const b = next_level.points - current_level.points
			return {percent: to_percent(a, b), points}
		})
	}
}

export default Gaming
