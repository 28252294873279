export default {
	"defaults": {
		"notification": {
			"showLevelAnimationTogetherWithBadgeAnimation": true
		}
	},
	"configProps": [
		"activities",
		"all_time_position",
		"inbox_activities",
		"private_activities",
		"quiet_mode",
		"unread_activities",
		"unread_inbox",
		"intervalTimeActivities",
		"intervalTimeInbox",
		"showLevelAnimationTogetherWithBadgeAnimation",
		"inboxSync",
		"read_only_player"
	]
}