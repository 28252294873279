export default {
    // The app's API key. Required.
    "api_key": null,
    // The app's client token. Optional. If provided, requests to Captain
    // Up will use the client token and the current user's access token to
    // authenticate requests, instead of the default session authentication.
    "client_token": null,
    // Show the console messages for a given level: 0, 1, 2, 3
    // See embed/utils/log for more details
    "debug": false,
    // If local is true then we're in local development mode
    "local": false,
    // If in `local` development mode, this domain will be used
    "local_address": "local.host",
    // If `quiet_mode` is set to true, no actions will be sent to
    // the server, and the app authorization notification won't
    // show up. This mode is used in the Captain Up admin panel.
    "quiet_mode": false,
    // Determines if we should create a cookie for this session or
    // not. If enabled, the current user id and a signed hash of
    // the user id are added to the site's cookies under `_cptup_sess`.
    //
    // This can be used by server-side code to get the current user id,
    // verify that it has not been tampered with, and send requests to
    // our API.
    "cookie": "false",
    // The custom app-specific user data. Can contain strings, booleans
    // and numbers. If it's used, it cannot be empty and must contain an
    // app-specific ID key for the user. The user object cannot contain
    // nested objects. It's used in app-specific user integration.
    // See: https://captainup.com/help/javascript/user-integration
    // And: https://github.com/mrkeyboard/cptup/issues/3590
    "player": {},
    // Optional. The signed user string contains a serialized version of
    // the user object, hashed with the app secret. It's used to verify
    // the user object was not tampered with on the client-side. It is
    // only needed if the app's `secure_user_integration` option is on.
    "signed_user": null,
    // Interval value in MS for fetching notifications from Captain Up
    "fetch_timer": 1000,
    // We want each call to captain.up while he is not logged in to restart the SDK instances.
    "restart": false,
    "recursive": false,
    "read_only_player": false,
    "domain": "https://captainup.com",
    "socketDomain": "wss://captainup.com"
} as const