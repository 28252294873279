import utils from '../../../utils'
import Communication from '../../../communication/communication'
import conf from './actions_config'
import * as _ from 'lodash'

let instance = null // init the instance.

/**
 * Actions class responsible of all action functionality and manage the state of user actions.
 * 
 * Part of the {@link User} module.
 * @category User
 */
class Actions implements Initiable<Actions> {
	loaded: boolean
	config: any

	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.actions, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.actions] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @returns Module is ready.
	 */
	init(config = {}, defaults = conf.defaults.actions, props = conf.configProps) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		this.loaded = true

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.actions.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.actions.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Notify the server on client action.
	 * @version 1.0.0
	 * @public
	 * @param name - Action name.
	 * @param [data = {}] - Action data.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Object>} Server response(whether the action sent successfully or not).
	 * @example
	 * ```
	 * captain.actions.send('gamePlay', {entity: {
	 * 	gamecode: 'superb',
	 * 	amountbase: 5
	 * }})
	 * .then(response => {
	 * 	// Action sent successfully...
	 * })
	 * ```
	 */
	send(name: string, data = {}, communication = new Communication) {
		// Check if the name, data and communication is valid.
		utils.validateDependencies([
			{name: 'name', type: 'String', val: name},
			{name: 'data', type: 'Object', val: data},
			{name: 'communication', type: 'Object', val: communication}
		])

		data = Object.assign({}, data, {name})
		const params = {type: 'action', action: data}
		const config = {requestType: 'http', method: 'post'} as const
		const domain = communication.getConfig().domain
		const url = `${domain}/mechanics/${communication.config.api_version}/actions`

		return communication.request(url, params, config)
	}
}

export default Actions
