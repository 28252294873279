import Gaming from './gaming/gaming'
import User from './user/user'
import Notification from './notification/notification'

import conf from './modules_config'
import utils from '../utils'
import * as _ from 'lodash'
import Sdk from '../sdk'

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function initialize_modules(sdk = new Sdk, config, defaults = conf.defaults.modules, props = conf.configProps) {
	const concatConfig = {}
	Object.assign(concatConfig, defaults, config)
	config = _.pick(concatConfig, props)

	sdk.modules ||= {}

	let user_module = utils.moduleInitializer<User>(sdk.modules, 'user', User, config)

	while (!user_module.loaded) {
		await sleep(30)
	}

	if (user_module.userNotFound === true) {
		const error = {
			'code': '401',
			'error': 'User Not Found'
		}
		sdk.clearState()
		return Promise.reject(error)
	}
	let gaming_module = utils.moduleInitializer<Gaming>(sdk.modules, 'gaming', Gaming, config)
	let notifications_module = utils.moduleInitializer<Notification>(sdk.modules, 'notification', Notification, config)

	while (!gaming_module.loaded || !user_module.loaded || !notifications_module.loaded) {
		await sleep(30)
	}

	return Promise.resolve()
}

export default initialize_modules