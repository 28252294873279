export default {
	"defaults": {
		"user":  {}
	},
	"configProps": [
		"client_token",
		"access_token",
		"id",
		"player",
		"user",
		"signed_user",
		"playtech_token",
		'playtech_client_platform',
		'playtech_client_type',
		"action_settings",
		"total_actions",
		"total_daily_actions",
		"total_monthly_actions",
		"total_weekly_actions",
		"read_only_player"
	]
}