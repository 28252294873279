export default {
    "defaults": {
        "http": {
            "post": {
                "credentials": "include",
                "headers": {
                    "Content-Type": "application/json"
                }
            },
            "delete": {
                "credentials": "include",
                "headers": {
                    "Content-Type": "application/json"
                }
            },
            "get": {
                "credentials": "include",
                "timeout": 60000
            }
        }
    },
    "configProps": [
        "method",
        "credentials",
		"post",
		"delete",
		"get"
    ]
}