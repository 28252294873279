import Init from './init/init'
import defaultConfig from './sdk_conf'
import * as _ from 'lodash';
import my_utils from './utils'
import {version} from '../package.json'

import type Notification from './modules/notification/notification'
import type Gaming from './modules/gaming/gaming'
import type User from './modules/user/user'
import { Communication, Http } from './modules-export';
import Levels from './modules/gaming/levels/levels';
import Tiers from './modules/gaming/tiers/tiers';
import Badges from './modules/gaming/badges/badges';
import Tournaments from './modules/gaming/tournaments/tournaments';
import Assets from './modules/gaming/assets/assets';
import Currencies from './modules/gaming/currencies/currencies';
import Activities from './modules/notification/activities/activities';
import Actions from './modules/user/actions/actions';
import Leaderboard from './modules/user/leaderboard/leaderboard';
import Inbox from './modules/notification/inbox/inbox';

let self = null

export interface SdkConfigOptions {
	/** The application API Key (found in the admin -> settings) */
	api_key: string
	user?: {
		id: string,
		name: string,
		[key: string]: any
	}
	restart?: boolean
	recursive?: boolean
	id?: string
	client_token?: string
	cookie?: boolean|string
	player?: any
	signed_user?: string
	fetch_timer?: number
	access_token?: string
	action_settings?: any
	read_only_player: boolean
	/**@private */
	quiet_mode?: boolean
	/**@private */
	debug?: boolean|number
	/**@private */
	local?: boolean
	/**@private */
	local_address?: string
	/**@private */
	domain?: string
	/**@private */
	socketDomain?: string
}

import {BadgeSettings, TournamentSettings} from './modules/gaming/gaming-types'
export interface InitializedSdkConfig {
	id: string
	/**@private */
	_id: string
	url: string // App url as defined in the admin
	restart: boolean
	recursive: boolean
	api_key: string
	client_token: string
	user?: any
	cookie: boolean|string
	player: any
	signed_user?: string
	fetch_timer: number
	domain: string
	access_token?: string
	country: string
	inboxSync: boolean
	updated_at: string
	created_at: string

	badges: BadgeSettings[]
	tournaments: TournamentSettings[]
	action_settings: any[]
	currencies: any[]
	reward_types: any[]
	assets: any[]
	levels: any[]
	tiers: any[]
	currency_conversion_types_values: any
	is_tiers_enabled: boolean

	tasks: any[]

	local?: boolean
	debug?: boolean|number
	dev?: boolean
	local_address?: string
	quiet_mode?: boolean
	protocol: 'http:' | 'https:'
	socketDomain: string
	read_only_player: boolean

	method: 'get'
	requestType: 'http' | 'https'
}

/**
 * Captain Up SDK is an API for all Captain Up functionality, it's allow you as a developer to create
 * custom widgets or custom behavior and reach to all captain up abilities.
 *
 * SDK module is the entry point for the entire SDK modules.
 * 
 * @class Sdk
 * @tutorial Getting-Started
 * @param config - Configurations object.
 */
class Sdk {
	private initializing: Promise<this>
	init: Init

	protected utils: typeof my_utils
	public ready: boolean
	private tasks: any[]
	config: InitializedSdkConfig
	version: string

	public inbox: Inbox
	public http: Http
	public levels: Levels
	public tiers: Tiers
	public badges: Badges
	public tournaments: Tournaments
	public assets: Assets
	public currencies: Currencies
	public activities: Activities
	public actions: Actions
	public leaderboard: Leaderboard

	communication: Communication

	/** @private */
	modules: {gaming?: Gaming, user?: User, notification?: Notification}
	gaming: Gaming
	user: User
	notification: Notification
	/**
	 * Construct the module.
	 * 
	 * You should use it through the {@link up} method, and not construct it yourself.
 	 * Read the tutorial for more details.
	 * @package
	 * @param config - Configurations object.
	 * @returns Module instance.
	 */
	constructor(config: SdkConfigOptions = defaultConfig) {
		if (!self || config.restart) {
			self = this
			if (!config.recursive)
				config.restart = null

			this.utils = my_utils
			this.ready = false
			this.tasks = []
			this.version = version
		}

		return self
	}

	/**
	 * Initializing the SDK or adding callbacks to execute when the SDK is ready.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param config - Config for initialize the SDK or callback to run after the SDK is ready
	 * @param initApi - Init class.
	 * @returns Sdk instance.
	 * @example
	 * ```
	 * captain.up({
	 *		api_key: '123', // Your app API key
	 *		lang: 'en-us', // Player language
	 *		country: 'united_kingdom', // Player country
	 *		currency: 'gbp', // Player currency
	 *		inbox: true, // Whether to sync inbox data
	 *		client_token: '8dc34c622ff476e8ba574bee813f05f48dbf68c7' // Client side token
	 *		signed_user: 'Y2U5M2Y2ZDdhM2Y4NmUyMmY5NTVlMzYyOGUxM2M3MGEyOTVkYzA4NmRkYTBjM', // Generated hash for user, see: https://captainup.com/help/javascript/user-integration#toc-secure-user-integration
	 *		user: { // Player data
	 *			id: '1234',
	 *			name: 'test-user'
	 *		}
	 *	})
	 * .then((captain) => console.log('captain up sdk initialized'))
	 * ```
	 *
	 * @example
	 * ```
	 * captain.up(() => console.log('captain up sdk initialized'))
	 * ```
	 */
	up(config: SdkConfigOptions|((sdk: this) => SdkConfigOptions) = defaultConfig, initApi = Init): Promise<Sdk | Number | SdkConfigOptions> {
		my_utils.validateDependencies([
			{name: 'config', type: ['Object', 'Function'], val: config},
			{name: 'initApi', type: 'Function', val: initApi}
		])

		const sdkInitDone = () => {
			this.createAlias()
			this.ready = true
			if (webpackGlobal.__isSdk__)
				this.tasks = this.execTasks(this.tasks)
			return this
		}
		const sdkInitFailed = (err) => {
			return Promise.reject(err)
		}
		const constructInitModule = (defaultConf, conf, tasks, module: typeof Init) => {
			// Merge between default config and initialize config.
			const concatConfig = Object.assign({}, defaultConf, conf, {tasks})
			// Construct the Init class.
			return new module(concatConfig)
		}

		if (!this.ready) {
			if (_.isFunction(config))
				return Promise.resolve(this.tasks.push(config))

			// We can't initialize the SDK without valid api key.
			if (!_.isString(config.api_key))
				return Promise.reject(new Error('\'api_key\' (String) is required parameter'))

			if (!config.api_key.match(/^[0-9a-fA-F]{24}$/))
				return Promise.reject(new Error('\'api_key\' (String) make sure you pass the right key'))

			if (config.user && _.isEmpty(config.user))
				return Promise.reject(new Error('\'user\' (Object) if exist, can\'t be empty'))

			this.init = constructInitModule(defaultConfig, config, this.tasks, initApi)
			// Start initializing the SDK.
			this.initializing = this.init.up()
				.then(() => sdkInitDone())
				.catch(sdkInitFailed)
			return this.initializing
		} else {
			if (_.isFunction(config))
				return Promise.resolve(config(self))
			return Promise.resolve(self)
		}
	}

	/**
	 * Indicate that the SDK is fully loaded and ready to use.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @returns Sdk instance.
	 * @example
	 * ```
	 * captain.isReady().then(() => console.log('captain up sdk initialized'))
	 * ```
	 */
	isReady(resolver?): Promise<Sdk> {
		return new Promise((resolve) => {
			resolver = resolver || resolve
			if (!this.ready) {
				setTimeout(() => this.isReady(resolver), 300)
			} else {
				return resolver(this.initializing)
			}
		})
	}

	/**
	 * Execute callback tasks.
	 * @version 1.0.0
	 * @private
	 * @param {Array} tasks=[] - Array of callbacks.
	 * @returns {Array} returned values of callbacks
	 */
	execTasks(tasks = []) {
		my_utils.validateDependencies([
			{name: 'tasks', type: 'Array', val: tasks}
		])

		return tasks.map((task) => task(self))
	}

	/**
	 * Create Alias(references) to modules on the SDK object.
	 * @version 1.0.0
	 * @private
	 */
	createAlias() {
		this.http = this.communication.http
		this.gaming = this.modules.gaming
		this.levels = this.modules.gaming.levels
		this.tiers = this.modules.gaming.tiers
		this.badges = this.modules.gaming.badges
		this.tournaments = this.modules.gaming.tournaments
		this.assets = this.modules.gaming.assets
		this.currencies = this.modules.gaming.currencies
		this.notification = this.modules.notification
		this.inbox = this.modules.notification.inbox
		this.activities = this.modules.notification.activities
		this.user = this.modules.user
		this.actions = this.modules.user.actions
		this.leaderboard = this.modules.user.leaderboard
	}

	/**
	 * Clear sdk state,
	 * In order to complete this process you need to `captain.up()` again with the follow properties: `{restart: true, recursive: true}`
	 * @version 1.0.0
	 * @private
	 */
	clearState() {
		// close socket connection
		const closeSocket = () => {
			if (!this.notification) {
				return
			}
			if (this.notification && this.notification.activities) {
				this.notification.activities.closeSocketConnection()
			}
		}
		const clearConfig = () => {
			this.ready = false
			this.tasks = []
			delete this.config
		}
		const clearTimers = () => {
			if (this.inbox && this.inbox.interval)
				this.inbox.stopSyncing()
		}
		closeSocket()
		clearTimers()
		clearConfig()

		return true
	}
}

export default Sdk
