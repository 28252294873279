import utils from '../../../utils'
import Sdk from '../../../sdk'
import BasicModule from '../../../basic-module'
import conf from './tiers_config'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'

let instance = null // init the instance.
let observable = null // init the observable.

/**
 * Tiers class responsible of all tier functionality and manage the state of user tiers.
 * 
 * Part of the {@link Gaming} module.
 * This is a class reference, find the tutorial here: {@link Tutorial_Tiers}
 * @category Gaming
 */
class Tiers extends BasicModule implements Initiable<Tiers> {
	loaded: boolean
	config: any
	state: Rxjs_subject<any>


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.tiers, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults=conf.defaults.tiers] - Defaults object.
	 * @param {Array} [props=conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.tiers, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!observable || config.test) {
			observable = new Rxjs_subject<any>()
			this.state = observable
		} else {
			this.state = observable
		}
		sdk.isReady()
			.then(() => this.get())
			.then((tier) => {
				this.setConfig({tier})
				this.state.next(tier)
			})
			.then(() => this.loaded = true)

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.tiers.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.tiers.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get user current tier
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Tier>} tier settings.
	 * @example
	 * captain.tiers.get()
	 * .then(tier => {
	 * 	// Get current player current tier data.
	 * })
	 * @example
	 * captain.tiers.get(playerId)
	 * .then(tier => {
	 * 	// Get specific player current tier data.
	 * })
	 */
	get(userId?, sdk = new Sdk) {
		// Check if userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		return sdk.user.get(userId).then(res => {
			return this.getTierByIndex(res.current_tier_index)
		})
	}

	/**
	 * Get user next tier
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @returns {Promise<Tier>} tier settings.
	 * @example
	 * captain.tiers.getNext()
	 * .then(tier => {
	 * 	// Get current player next tier data.
	 * })
	 * @example
	 * captain.tiers.getNext(playerId)
	 * .then(tier => {
	 * 	// Get specific player next tier data.
	 * })
	 */
	getNext(userId) {
		// Check if the userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId}
		])

		const getNextFromCurrent = ([current, list]) => {
			const nextTierNumber = current.number + 1
			return list.find((tier) => tier.number === nextTierNumber) || current
		}

		return Promise.all([this.get(userId), this.getList()])
			.then(getNextFromCurrent)
	}

	/**
	 * Get all tier settings.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<Tier>>} List of tiers settings.
	 * @example
	 * captain.tiers.getList()
	 * .then(tiers => {
	 * 	// Get app all tiers settings.
	 * })
	 */
	getList(sdk = new Sdk) {
		// Check if the sdk is valid.
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk}
		])

		return sdk.init.getSDKConfig().then((res) => {
			const tiers = res.tiers || []
			return tiers.sort((a, b) => a.currency - b.currency)
		})
	}

	/**
	 * Get specific tier settings.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} tierIndex - index of specific tier.
	 * @returns {Promise<Tier>} tier settings.
	 * @example
	 * captain.tiers.getTierByIndex('*specificTierIndex*')
	 * .then(tier => {
	 * 	// Get app specific tier settings.
	 * })
	 */
	getTierByIndex(tierIndex: number, sdk = new Sdk) {
		// Check if the tierIndex is valid.
		utils.validateDependencies([
			{name: 'tierIndex', type: 'Number', val: tierIndex}
		])

		return this.getList().then((tiers) => {
			const tier = tiers[tierIndex]
			return tier
		})
	}

	/**
	 * Update user tiers state.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Tier} tier - Current player tier data.
	 * @return  {Promise<Object>} tier settings.
	 */
	update(tier) {
		this.setConfig({tier})
		this.state.next(tier)
		return Promise.resolve(tier)
	}

	/**
	 * Process activity to tier structure for notification.
	 * @version 1.0.0
	 * @private
	 * @param {Tier} tier - Tier settings.
	 * @param l - Locale data.
	 * @returns {Object} Structured params.
	 */
	processActivity(tier, l = (utils.get_environment_global_var())['captain'].l || {}) {
		// Check if tier and l is valid.
		utils.validateDependencies([
			{name: 'tier', type: 'Object', val: tier},
			{name: 'l', type: 'Object', val: l},
		])

		const params = {
			type: 'tier',
			data: {
				name: tier.name,
				image: tier.preset_image,
				item_id: tier.id,
				tier,
				l
			}
		}
		return params
	}
}

export default Tiers
