/**
 * BasicModule is an empty class utility for us to generalize sdk modules
 * for typescript.
 * @class BasicModule
 * @private
 */
export default class BasicModule {

  constructor() {
    
  }
}