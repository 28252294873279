export default {
	"defaults": {
		"inbox": {
			// Unread inbox requests default interval time value
			"intervalTimeInbox": 60000,
			"inboxSync": true
		}
	},
	"configProps": [
		"inbox_activities",
		"quiet_mode",
		"unread_inbox",
		"intervalTimeInbox",
		"inboxSync",
		"read_only_player"
	]
}