import utils from '../../../utils'
import Communication from '../../../communication/communication'
import Sdk from '../../../sdk'
import conf from './badges_config'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'
import BasicModule from '../../../basic-module'

import {BadgeSettings} from '../gaming-types'

let instance = null // init the instance.
let state = null // init the state observable.
let updates = null // init the updates observable.


/**
 * Find differences betweens two badge_progress objects by badges progress.
 * @param {import("../gaming-types").AssetProgress} progressA
 * @param {import("../gaming-types").AssetProgress} progressB
 * @private
 */
const getProgressDiff = (progressA, progressB) => {
	// First we re-structure the badge progress to an array of badge ID and badge progress (`[{key: '1asfa241x', progress: 0.5}]`)
	const newProgressMapped = _.map({...progressA, updated: {}}, ({progress}, id) => ({id, progress}))
	const oldProgressMapped = _.map({...progressB, updated: {}}, ({progress}, id) => ({id, progress}))
	return _.differenceBy(newProgressMapped, oldProgressMapped, 'progress')
		.map(badge => badge.id)
}

/**
 * Badges class responsible of all badge functionality and manage the state of user badges.
 * 
 * Part of the {@link Gaming} module.
 * 
 * This is a class reference, find the tutorial here: {@link Tutorial_Badges}
 * @category Gaming
 */
class Badges extends BasicModule implements Initiable<Badges> {
	loaded: boolean
	config: any
	/** Expose the badges state(can be set to fire on every change and not only on badge completion). */
	state: Rxjs_subject<any>
	/** Expose updates in specific badges. */
	updates: Rxjs_subject<any>


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.badges, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults=conf.defaults.badges] - Defaults object.
	 * @param {Array} [props=conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.badges, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = Object.assign({}, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!state || !updates || config.test) {
			state = new Rxjs_subject<any>()
			this.state = state
			updates = new Rxjs_subject<any>()
			this.updates = updates
		} else {
			this.state = state
			this.updates = updates
		}
		sdk.isReady().then(() => {
			// Update the badge progress.
			this.config.progress = sdk.config.player.badge_progress
			// Update the state when the SDK is ready.
			this.get().then((badges) => {
				this.state.next(badges)
			}).then(() => this.loaded = true)
		})

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.badges.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.badges.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get sorted list of all badges and their progress.
	 * We are filter out unfinished badges of another player from the list.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {BadgeSettings=} badges_settings - Badges settings data.
	 * @param {import("../gaming-types").AssetProgress=} badge_progress - Player `badge_progress` data.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<Badge>>} Badges list
	 * @example
	 * captain.badges.get()
	 * .then(badges => {
	 * // Get current player badges data with progress.
	 * })
	 * @example
	 * captain.badges.get(playerId)
	 * .then(badges => {
	 * 	// Get specific player badges data with progress.
	 * })
	 */
	get(userId?, badges_settings?: BadgeSettings, badge_progress?, sdk = new Sdk) {
		// Check if the userId, badge_progress and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'badges_settings', type: ['Array', 'Undefined'], val: badges_settings},
			{name: 'badge_progress', type: ['Object', 'Undefined'], val: badge_progress},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// Assign to current if userId is unassigned
		if(!userId) {
			userId = sdk.config.player.id
		}

		// Get list of badge settings and badge progress.
		return Promise.all([
			...(badges_settings? [badges_settings] : [this.getList()]),
			...(badge_progress? [badge_progress] : [this.getProgress(userId)])
		])
		.then(([listResolve, progressResolve]) => {
			// Update the static config data with the resolved lists.
			this.setConfig({progress: progressResolve, badges: listResolve})
			// Merge the lists.
			let badges = this.attachPlayerData(listResolve, progressResolve, sdk.config.player.unavailable_segmented_badges)
			// Sort the list.
			.sort(this.sortBadges)

			// If the user is not current -> filter the unfinished badges.
			if(userId !== sdk.config.player.id) {
				badges = this.filterUnfinishedBadges(badges)
			}

			return badges
		})
		.then(this.claimableRewards)
	}

	/**
	 * Sort the badge list by completion, progress and name.
	 * @version 1.0.0
	 * @private
	 * @param {Badge} badgeA - Badge object.
	 * @param {Badge} badgeB - Badge object.
	 * @returns {Number} whether badgeA should be before badgeB.
	 */
	sortBadges(badgeA, badgeB) {
		const sortByName = (badgeA, badgeB) => (badgeA.name || '').localeCompare(badgeB.name || '')

		// Check if the badgeA and badgeA is valid.
		utils.validateDependencies([
			{name: 'badgeA', type: 'Object', val: badgeA},
			{name: 'badgeB', type: 'Object', val: badgeB}
		])

		// Sort by name if both are completed
		if(badgeA.progress.completed && badgeB.progress.completed)
			return sortByName(badgeA, badgeB)

		// If only one of them was completed, sort by completion
		if(badgeA.progress.completed)
			return -1

		if(badgeB.progress.completed)
			return 1

		// Otherwise, sort by badge progress, then by name
		return (badgeB.progress.progress - badgeA.progress.progress) || sortByName(badgeA, badgeB)
	}

	/**
	 * Filter the unfinished badges.
	 * @version 1.0.0
	 * @private
	 * @param {Array<Badge>} [badges = []] - Badges list.
	 * @returns {Array<Badge>} Completed badge list
	 */
	filterUnfinishedBadges(badges = []) {
		// Check if badges is valid.
		utils.validateDependencies([
			{name: 'badges', type: 'Array', val: badges}
		])
		return badges.filter((badge) => {
			if(!badge || !badge.progress)
				return false
			return (badge.progress.completed)
		})
	}

	/**
	 * Merge the badge settings with the specific player data(like badge progress and segmentation).
	 * @version 1.0.0
	 * @private
	 * @param {Array<BadgeSettings>} listResolve - Badge setting list.
	 * @param {Object<BadgeProgress>} progressResolve - Badge progress list.
	 * @param {Array} unavailable_segmented_badges - Badge segmentation list.
	 * @returns {Array<Badge>} Badges list with progress merged to settings.
	 */
	attachPlayerData(listResolve, progressResolve, unavailable_segmented_badges) {
		// Check if listResolve, progressResolve and unavailable_segmented_badges is valid.
		utils.validateDependencies([
			{name: 'listResolve', type: 'Array', val: listResolve},
			{name: 'progressResolve', type: ['Object', 'Undefined'], val: progressResolve},
			{name: 'unavailable_segmented_badges', type: 'Array', val: unavailable_segmented_badges}
		])
		// Changed loop because of change in cptup for optimized badge_progress which return only badge_progress with progress > 0
		return _.map(listResolve, (badgeItem) => {
			// Check if progress exists for this badge
			const badgeProgress = progressResolve?.[badgeItem._id] || {}
			// If progress doesn't exist, set it to empty object
			const progress = badgeProgress ? badgeProgress : {}
			return {
				...badgeItem,
				progress: progress,
				is_segmented_for_user: !unavailable_segmented_badges.includes(badgeItem.id)
			}
		})
		// Filter the 'false' elements.
		.filter((badge) => badge)
	}

	/**
	 * Adds to rewards indication of whether they are claimable or not.
	 * @version 1.0.0
	 * @private
	 * @param {Array<Badge>} badges - Badge setting list.
	 * @returns {Array<Badge>} Badges list.
	 */
	claimableRewards(badges = [], sdk = new Sdk) {
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk},
		])
		// Checks if the reward is claimable or not.
		// If user.available_rewards contains reward id then it's claimable. 
		const isClaimable = reward => {
			const available_rewards = sdk.user.getConfig().available_rewards
			if (_.get(available_rewards, 'length', 0)) {
				const available_reward = _.find(available_rewards, {id: reward._id})
				// timestamps is an array of datetime string when reward was awarded and only of non claimed reward
				if (_.get(available_reward, 'timestamps.length', 0)) {
					return true
				}
			}
			return false
		}

		// Adds `claimable` property to each reward.
		const addClaimableProp = reward => {
			return {
				...reward,
				claimable: isClaimable(reward)
			}
		}

		// Iterate over each badge and each of his rewards and add `claimable` property.
		return badges
			.map(badge => ({
				...badge,
				rewards: [
					...(badge.rewards || []).map(addClaimableProp)
				]
			}))
	}

	/**
	 * Get list of badge settings
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<BadgeSettings[]>} Badge setting list instance.
	 * @example
	 * captain.badges.getList()
	 * .then(badges => {
	 * 	// Get app all badges settings.
	 * })
	 */
	async getList(sdk = new Sdk): Promise<BadgeSettings[]> {
		// Check if sdk is valid.
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk}
		])

		let app_settings = await sdk.init.getSDKConfig()
		const badges = app_settings.badges
		const reward_types = app_settings.reward_types

		const getRewardType = (id, types) => types.find(type => type._id === id) || id
		const attachRewardType = reward => ({...reward, reward_type: getRewardType(reward.reward_type, reward_types)})
		const attachRewards = badge => ({...badge, rewards: badge.rewards.map(attachRewardType)})
		return badges.map(attachRewards)
	}

	/**
	 * Get list of badge progress
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User Id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Object<BadgeProgress>>} Badge progress list instance.
	 * @example
	 * captain.badges.getProgress()
	 * .then(progress => {
	 * 	// Get player badge progress.
	 * })
	 */
	getProgress(userId, sdk = new Sdk) {
		// Check if userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// Fetch the progress from server.
		return sdk.user.get(userId)
			.then(res => res.badge_progress)
	}

	/**
	 * Get badge with setting and progress by id.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} id - Badge Id.
	 * @returns {Promise<Badge>} Specific badge.
	 * @example
	 * captain.badges.getBadge('*specificBadgeId*')
	 * .then(badge => {
	 * 	// Get app specific badge settings with progress.
	 * })
	 */
	getBadge(id) {
		// Check if id is valid.
		utils.validateDependencies([
			{name: 'id', type: 'String', val: id}
		])
		return this.get().then((badges) => badges.find((badge) => (badge._id === id)))
	}

	/**
	 * Save the updated calculate badge list and push the update to observable.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Badge=} badge - Badge object.
	 * @param {Object<BadgeProgress>=} badge_progress - Updated player `badge_progress` data.
	 * @returns {Promise<Array<Badge>>} Updated badge list.
	 */
	update(badge, badge_progress) {
		// Check if badge and badge_progress is valid.
		utils.validateDependencies([
			{name: 'badge', type: ['Object', 'Undefined'], val: badge},
			{name: 'badge_progress', type: ['Object', 'Undefined'], val: badge_progress}
		])

		// This method handle 3 cases:
		// 1. Player achieved badge.
		// 2. Player progress changed but he didn't achieved badge.
		// 3. Player progress didn't changed and he didn't achieved badge.

		let getDependencies = [undefined, undefined, badge_progress]
		let diffs = []
		// Get latest badges settings.
		const {badges: badges_old, progress: badge_progress_old, sensitive_badge_state} = this.getConfig()

		// Shallow check if some data was changed in the `badge_progress`
		if (sensitive_badge_state)
			diffs = getProgressDiff(badge_progress, badge_progress_old)

		// If 'badge' is falsy it means that we in `sensitive_badge_state` mode and we didn't achieved any badge.
		if (!badge) {
			if (_.isEmpty(diffs))
				return this.get(undefined, badges_old, badge_progress)

			getDependencies = [undefined, badges_old, badge_progress]
		}

		return this.get(...getDependencies)
			.then(badges => {
				this.setConfig({progress: badge_progress, badges})
				this.state.next(badges)
				// Trigger `updates` events for any badge progress that was changed.
				badges.forEach(innerBadge => {
					if (sensitive_badge_state && innerBadge.id !== (badge || {}).id && diffs.includes(innerBadge.id))
						this.updates.next(innerBadge)
				})

				if (badge)
					this.updates.next(badge)

				return badges
			})
	}

	/**
	 * Process activity to badge structure.
	 * @version 1.0.0
	 * @private
	 * @param {Badge} badge - Badge settings and progress.
	 * @param l - Locale data.
	 * @returns {Object} Structured params.
	 */
	processActivity(badge, l = (utils.get_environment_global_var())['captain'].l || {}) {
		// Check if badge and l is valid.
		utils.validateDependencies([
			{name: 'badge', type: 'Object', val: badge},
			{name: 'l', type: 'Object', val: l},
		])

		const params = {
			type: 'badge',
			points: badge.points,
			data: {
				name: badge.name,
				image: badge.preset_image,
				item_id: badge._id,
				badge,
				l
			}
		}
		return params
	}

	/**
	 * Claim badge reward.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} badgeId - Badge ID.
	 * @param {String} rewardId - Reward ID.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Object>} Reward data.
	 */
	claim(badgeId, rewardId, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'badgeId', type: ['Object', 'String'], val: badgeId},
			{name: 'rewardId', type: 'String', val: rewardId},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])
		const updateBadgeProgress = (reward) => {
			this.setConfig({progress: reward.badge_progress})
			return reward
		}
		const updateBadges = (reward) => {
			return this.get().then((badges) => {
				this.state.next(badges)
				return reward
			})
		}

		const updateAvailableRewards = (reward) => {
			sdk.user.setConfig({available_rewards: reward.available_rewards})
			return reward
		}

		const badge_id = _.isString(badgeId) ? badgeId : badgeId.id

		const params = {
			app_id: sdk.config.id,
			badge_id: badge_id,
			reward_id: rewardId,
			object_identifier: rewardId,
			object: 'reward'
		}
		const config = {
			requestType: 'http',
			method: 'post'
		} as const
		const cb = (x) => x
		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.id}/badges/${params.badge_id}/rewards/${params.reward_id}/claim`
		return communication.request(url, params, config, cb)
			.then((reward) => updateBadgeProgress(reward))
			.then((reward) => updateBadges(reward))
			.then((reward) => updateAvailableRewards(reward))
	}

	/**
	 * Get full badges achievements history data.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Array|String} badgesId - Badges ID.
	 * @param {Number=} limit - Max number of history rows.
	 * @param {Number=} skip -  Amount of rows to skip from zero.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Array<BadgeHistory>>} badges achievements data.
	 */
	history(badgesId, limit, skip, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'badgesId', type: ['Array', 'String'], val: badgesId},
			{name: 'limit', type: ['Number', "Undefined"], val: limit},
			{name: 'skip', type: ['Number', "Undefined"], val: skip},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])

		const params = {
			app: sdk.config.id,
			badges: badgesId,
			limit,
			skip
		}

		const config = {
			requestType: 'http',
			method: 'get'
		} as const

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/players/${sdk.config.player.id}/badges/history`
		return communication.request(url, params, config)
	}
}

export default Badges
