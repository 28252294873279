export default {
    "defaults": {
        "activities": {
            "intervalTimeActivities": 10000
        }
    },
    "configProps": [
        "activities",
        "all_time_position",
        "private_activities",
        "quiet_mode",
        "unread_activities",
        "intervalTimeActivities",
    ]
}