import utils from '../../../utils'
import Sdk from '../../../sdk'
import BasicModule from '../../../basic-module'
import conf from './levels_config'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'

let instance = null // init the instance.
let observable = null // init the observable.

/**
 * Levels class responsible of all level functionality and manage the state of user levels.
 * 
 * Part of the {@link Gaming} module.
 * This is a class reference, find the tutorial here: {@link Tutorial_Levels}
 * @category Gaming
 */
class Levels extends BasicModule implements Initiable<Levels> {
	loaded: boolean
	config: any
	state: Rxjs_subject<any>


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.levels, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults=conf.defaults.levels] - Defaults object.
	 * @param {Array} [props=conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.levels, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!observable || config.test) {
			observable = new Rxjs_subject<any>()
			this.state = observable
		} else {
			this.state = observable
		}
		sdk.isReady()
			.then(() => this.get())
			.then((level) => {
				this.setConfig({level})
				this.state.next(level)
			})
			.then(() => this.loaded = true)

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.levels.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.levels.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get user current level
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Level>} level settings.
	 * @example
	 * captain.levels.get()
	 * .then(level => {
	 * 	// Get current player current level data.
	 * })
	 * @example
	 * captain.levels.get(playerId)
	 * .then(level => {
	 * 	// Get specific player current level data.
	 * })
	 */
	get(userId?, sdk = new Sdk) {
		// Check if userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		return sdk.user.get(userId).then(res => this.getLevel(res.level))
	}

	/**
	 * Get user next level
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @returns {Promise<Level>} level settings.
	 * @example
	 * captain.levels.getNext()
	 * .then(level => {
	 * 	// Get current player next level data.
	 * })
	 * @example
	 * captain.levels.getNext(playerId)
	 * .then(level => {
	 * 	// Get specific player next level data.
	 * })
	 */
	getNext(userId) {
		// Check if the userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId}
		])

		const getNextFromCurrent = ([current, list]) => {
			const nextLevelNumber = current.number + 1
			return list.find((level) => level.number === nextLevelNumber) || current
		}

		return Promise.all([this.get(userId), this.getList()])
			.then(getNextFromCurrent)
	}

	/**
	 * Get all level settings.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<Level>>} List of levels settings.
	 * @example
	 * captain.levels.getList()
	 * .then(levels => {
	 * 	// Get app all levels settings.
	 * })
	 */
	getList(sdk = new Sdk) {
		// Check if the sdk is valid.
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk}
		])

		return sdk.init.getSDKConfig().then(res => res.levels)
	}

	/**
	 * Get specific level settings.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} levelId - Id of specific level.
	 * @returns {Promise<Level>} level settings.
	 * @example
	 * captain.levels.getLevel('*specificLevelId*')
	 * .then(level => {
	 * 	// Get app specific level settings.
	 * })
	 */
	getLevel(levelId: string) {
		if(_.isObject(levelId)) {
			//@ts-ignore
			levelId = levelId.id
		}
		// Check if the levelId is valid.
		utils.validateDependencies([
			{name: 'levelId', type: 'String', val: levelId}
		])

		return this.getList().then((levels) => {
			return levels.find((level) => level.id === levelId)
		})
	}

	/**
	 * Update user levels state.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Level} level - Current player level data.
	 * @return  {Promise<Object>} level settings.
	 */
	update(level) {
		this.setConfig({level})
		this.state.next(level)
		return Promise.resolve(level)
	}

	/**
	 * Process activity to level structure.
	 * @version 1.0.0
	 * @private
	 * @param {Level} level - Level settings.
	 * @param l - Locale data.
	 * @returns {Object} Structured params.
	 */
	processActivity(level, l = (utils.get_environment_global_var())['captain'].l || {}) {
		// Check if level and l is valid.
		utils.validateDependencies([
			{name: 'level', type: 'Object', val: level},
			{name: 'l', type: 'Object', val: l},
		])

		const params = {
			type: 'rank',
			data: {
				name: level.name,
				image: level.preset_image,
				item_id: level.id,
				level,
				l
			}
		}
		return params
	}
}

export default Levels
