import Actions from './actions/actions'
import Leaderboard from './leaderboard/leaderboard'

import conf from './user_config'
import * as _ from 'lodash'
import utils from '../../utils'
import Communication from '../../communication/communication'
import Sdk from '../../sdk'

import { AssetProgress } from '../gaming/gaming-types'
import { get_mustache_context, render_template } from '../../lib/render_template_string'

let instance = null

export interface UserConfig {
	client_token?: string
	access_token?: string
	id?: string
	player?: Object
	user?: Object
	signed_user?: string
	action_settings?: Object
	total_actions?: number
	total_daily_actions?: number
	total_monthly_actions?: number
	total_weekly_actions?: number
	available_rewards?: any[]

	/** @private */
	playtech_token?: string
	/** @private */
	playtech_client_platform?: string
	/** @private */
	playtech_client_type?: string
}

export interface UserState {
	id: string
	client_token: string
	player: any
	user?: {
		name: string
		id: string
	}
	signed_user?: string
	hashed_id: string
	access_token: string
	app_specific_id?: string
	name: string
	app_data: Object
	last_activity: string
	member_since: string
	currently_active_and_registered_tournaments: string[]
	achieved_badges: any[]
	available_rewards: any[]
	private_activities?: any[]
	activities: any[]
	badge_progress: AssetProgress[]
	tournament_progress: AssetProgress[]
	currency_spent: Object
	action_counter: {
		[key: string]: number
	}
	all_time_position: number
	monthly_position: number
	weekly_position: number
	daily_position: number
	monthly_points: number
	weekly_points: number
	daily_points: number
	currencies: {
		[key: string]: number
	}
	acquired_assets: any[]
	image: string
	level: string
	inbox: any[]
	unread_inbox: any[]
	unread_activities: any[]
	full_profile: boolean
	unavailable_segmented_assets: any[]
	unavailable_segmented_badges: any[]
	unavailable_segmented_tournaments: any[]
	is_anonymous: boolean
	read_only_player: boolean
	current_tier_index: number
}

/**
 * User class responsible of all user functionality and manage the state of user.
 * @category User
 */
class User implements Initiable<User> {
	config: UserConfig
	loaded: boolean

	userNotFound: boolean
	readOnlyRequestCounter: number
	actions: Actions
	leaderboard: Leaderboard

	/**
	 * Construct the module.
	 * @private
	 * @async
	 * @param {UserConfig} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config: UserConfig = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.user, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {UserConfig} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.user] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: UserConfig = {}, defaults = conf.defaults.user, props = conf.configProps) {
		const concatConfig = Object.assign({}, defaults, config)
		this.config = _.pick(concatConfig, props)
		this.readOnlyRequestCounter = 0;
		this.userNotFound = false
		this.getUserDetails()
		return this
	}

	/**
	 * Get User Details
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param sdk - Sdk instance.
	 */
	async getUserDetails(sdk = new Sdk) {
		const res = await this.get(undefined, undefined, true)
		// If user not found response will have code property with value 401.
		if(res) {
			if (res.hasOwnProperty('code')) {
				this.userNotFound = true
			} else {
				this.update(res)
				await this.initSubModules(res)
			}
		}
		this.loaded = true
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.user.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.user.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get player data.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param playerId - Player id to receive, if none given will get current player in session.
	 * @param cb - A callback to handle the player fetch request.
	 * @param authenticate - Indicate wether we should authenticate the player or just fetching
	 * @param forceFetch - Whether to force a fetch from remote source or using cache if exist.
	 * his data.
	 * @param communication - Communication instance.
	 * @param sdk - Sdk instance.
	 * @returns {Promise<UserState>} Promise of user data.
	 * @example
	 * ```
	 * captain.user.get()
	 * .then(player => {
	 * 	// Get current player data(see example in the link in the description).
	 * })
	 * ```
	 * @example
	 * ```
	 * captain.user.get(playerId)
	 * .then(player => {
	 * 	// Get specific player data(see example in the link in the description).
	 * })
	 * ```
	 */
	async get(
		playerId?: string,
		cb?: (player: any) => any,
		authenticate = false,
		forceFetch = false,
		communication = new Communication,
		sdk = new Sdk): Promise<UserState>
		{
		// Check if the userId, communication and sdk is valid.
		utils.validateDependencies([
			{name: 'playerId', type: ['String', 'Undefined'], val: playerId},
			{name: 'cb', type: ['Function', 'Undefined'], val: cb},
			{name: 'authenticate', type: ['Boolean', 'Undefined'], val: authenticate},
			{name: 'forceFetch', type: ['Boolean'], val: forceFetch},
			{name: 'communication', type: 'Object', val: communication},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// get request will not create / register the user. It will only fetch if the user already exist / registered
		// post request will create / update the user
		const httpMethod = sdk.config.read_only_player ? 'get' : 'post'
		
		// there are three ways to fetch player data, where two of them are for the current player and one for fetching
		// another player data, the two first are using `user` object with `signed_user` and using session in the
		// server, in order to fetch another player data we just sends his id and we get his public data.
		//
		// In our API those ways are structure like this:
		// /mechanics/v2/players/*playerId* - to fetch another player public data.
		// /mechanics/v2/app/*app*/players - to fetch current player data using `user` object with `signed_user`.
		const regularIntegration = (moduleConfig, playerId) => ({
			url: `${sdk.config.domain}/mechanics/${communication.config.api_version}/players/${playerId}`,
			params: {
				app: sdk.config.api_key
			}
		})

		const secureIntegration = (moduleConfig, signed_user?) => ({
			url: `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.api_key}/players`,
			params: {
				user: moduleConfig.user,
				signed_user: signed_user || moduleConfig.signed_user
			}
		})
		// Playtech integration is unique in that PT customers are not passing generated `signed_user` as a secured key
		// but PT temporary hash key that we are verifying with PT servers and then in turn generating for the player his `signed_user`.
		const playtechIntegration = (moduleConfig, config) => {
			// Preparing the special auth request for playtech customers
			const tokenUrl = `${sdk.config.domain}/mechanics/${communication.config.api_version}/playtech_auth`
			const tokenParams = {
				app: sdk.config.api_key,
				// Adding playtech params if exist.
				playtech_token: moduleConfig.playtech_token,
				user: moduleConfig.user,
				...(moduleConfig.playtech_client_platform) ? {playtech_client_platform: moduleConfig.playtech_client_platform} : {},
				...(moduleConfig.playtech_client_type) ? {playtech_client_type: moduleConfig.playtech_client_type} : {}
			}
			return communication.request(tokenUrl, tokenParams, config)
				//@ts-ignore
				.then(signed_user => {
				// The response of playtech auth is a `signed_user` for the player, we save it to the config for future
				// requests
					this.setConfig({signed_user})
					// If we want to authenticate the player (usually the first request for player data) we need to use POST
					// method for the request
					if (authenticate) {
						config.method = httpMethod
					}
					// Perform a regular server integration
					return secureIntegration(moduleConfig, signed_user)
				})
		}

		// Request config
		const config = {
			requestType: 'http' as const,
			method: 'get'
		}

		if (_.isUndefined(playerId) || playerId === this.config.id) {
			playerId = 'current'
			// If the call not force a fetch from remote source and we already have the player in cache we use it, but
			// only if the customer didn't pass any callback since we can't cache the request metadata.
			if (!forceFetch && !_.isEmpty(sdk.config.player) && !cb) {
				if (!sdk.config.player.templates_rendered) {
					this.renderTemplatesForUser(sdk, sdk.config.player)
				}
				return Promise.resolve(sdk.config.player)
			}
		}

		const requestDetails = (moduleConfig) => {
			// In case we request another player information.
			if (!(_.isObject(moduleConfig.user) && !_.isEmpty(moduleConfig.user) && playerId === 'current')) {
				return Promise.resolve(regularIntegration(moduleConfig, playerId))
			}
			// In case we need to fetch current player base on `user` object with `signed_user`.
			// Since PT users doesn't have signed_user but PT temp token we first resolve the temp token and get
			// `signed_user` hash in response
			if (moduleConfig.playtech_token && !moduleConfig.signed_user) {
				return playtechIntegration(moduleConfig, config)
			}

			// If we want to authenticate the player (usually the first request for player data) we need to use POST
			// method for the request
			if (authenticate) {
				config.method = httpMethod
			}

			return Promise.resolve(secureIntegration(moduleConfig))
		}

		try {
			const res = await requestDetails(this.config)
			const {url, params} = res
			this.userNotFound = false
			const response = await communication.request(url, params, config, cb)

			if (playerId === 'current') {
				this.renderTemplatesForUser(sdk, res)
			}

			this.readOnlyRequestCounter = 0
			return response
		} catch (error) {
			if (error.code === 401) {
				const res = await this.retryForReadOnlyRequest()
				this.userNotFound = true
				if (res) {
					this.userNotFound = false
					return res
				}
				return error
			}
		}	
	}

	async retryForReadOnlyRequest(sdk = new Sdk): Promise<UserState> {
		if (sdk.config.read_only_player === false) return

		// it will retry 3 times
		if (this.readOnlyRequestCounter < 3) {
			await this.sleep(4000)
			this.readOnlyRequestCounter++
			const res = await this.get(undefined, undefined, true)
			if (res && !res.hasOwnProperty('code')) {
				const initSubModulesRes = this.update(res)
				if (initSubModulesRes) {
					await this.initSubModules(initSubModulesRes)
					this.loaded = true
				}
				this.userNotFound = false
			} else {
				this.userNotFound = true
			}
			return res
		} else {
			// tried 3 times and still user does not exist
			this.userNotFound = true
			this.readOnlyRequestCounter = 0
		}
		return
	}

	sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	renderTemplatesForUser(sdk, player_obj) {
			// Render templates in all app items
			// -----------------------------------
			// render badges, levels, assets, tournaments
			for (let item_type of ['badges', 'levels', 'assets', 'tournaments', 'tiers']) {
				if (sdk.config[item_type] === undefined) { continue }
				for (let item of sdk.config[item_type]) {
					let mustache_context = get_mustache_context(player_obj, item_type.slice(0, -1), item)
					for (let attr_to_render of ['description', 'description_html', 'name']) {
						item[attr_to_render] = render_template(item[attr_to_render], mustache_context)
					}
				}
			}

			// Render templates of player data
			// ------------------------------------------
			// inbox_activities, unread_inbox
			for (let item_type of ['inbox_activities', 'unread_inbox']) {
				if (player_obj[item_type] === undefined) { continue }
				for (let item of player_obj[item_type]) {
					let mustache_context = get_mustache_context(player_obj, 'message', item)
					for (let attr_to_render of ['content', 'content_markdown', 'short_content', 'title']) {
						item.entity[attr_to_render] = render_template(item.entity[attr_to_render], mustache_context)
					}
				}
			}

			// Mark the player as rendered
			player_obj.templates_rendered = true
	}

	/**
	 * Set player configurations with the fetched data.
	 * @version 1.0.0
	 * @private
	 * @param {Object} player - Player data.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns SDK configurations.
	 */
	update(player, sdk = new Sdk) {
		// Check if the res and sdk is valid.
		utils.validateDependencies([
			{name: 'player', type: 'Object', val: player},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		Object.assign(this.config, player)
		sdk.config.player = this.config
		// Updating the embed player object (backward comparability)
		if ((utils.get_environment_global_var())['captain'] && webpackGlobal.__isEmbed__)
			(utils.get_environment_global_var())['captain'].player = Object.assign({}, (utils.get_environment_global_var())['captain'].player, this.config)
		return this.config
	}

	/**
	 * Initialize user sub-modules.
	 * @version 1.0.0
	 * @async
	 * @private
	 * @param {Object} config - SDK configurations.
	 * @param {Class} [actions = Actions] - Actions module.
	 * @param {Class} [leaderboard = Leaderboard] - Leaderboard module.
	 * @returns {Promise<Array>} Modules instance.
	 */
	initSubModules(config, actions = Actions, leaderboard = Leaderboard) {
		// Check if the config is valid.
		utils.validateDependencies([
			{name: 'config', type: 'Object', val: config}
		])

		const asyncInit = []
		asyncInit.push(utils.moduleInitializer<Actions>(this, 'actions', actions, config))
		asyncInit.push(utils.moduleInitializer<Leaderboard>(this, 'leaderboard', leaderboard, config))

		return Promise.all(asyncInit)
	}

	/**
	 * Logging out from current user.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Boolean} [toReload = true] - To reload the page.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [locationApi = location] - Location reference.
	 * @returns {Promise<Boolean>} - Whether the user is logged out.
	 * @example
	 * ```
	 * captain.user.logout() // Logout and reload the page.
	 * ```
	 * @example
	 * ```
	 * captain.user.logout(false) // Logout without reload.
	 * .then(isUserLoggedOut => {
	 * 	// Whether player is logged out.
	 *  // Now in order to re-login with a different player we need to pass to the init config: `restart: true, recursive: true`
	 *  return captain.up({...initConfig, restart: true, recursive: true})
	 * })
	 * .then(captain => {
	 *  // Now we are logged in with new player.
	 * })
	 * ```
	 */
	logout(toReload = true, communication = new Communication, sdk = new Sdk, locationApi = location): Promise<Boolean> {
		utils.validateDependencies([
			{name: 'toReload', type: 'Boolean', val: toReload},
			{name: 'communication', type: 'Object', val: communication},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'locationApi', type: 'Object', val: locationApi}
		])
		const url = `${sdk.config.domain}/logout`
		const reload = () => locationApi.reload()

		// @ts-ignore
		return communication.request(url).then(() => toReload ? reload() : sdk.clearState())
	}

	/**
	 * logout the user. mostly used to re login the user
	 * In order to complete this process you need to `captain.up()` again with the follow properties: `{restart: true, recursive: true}`
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Boolean} - user logged out.
	 * @returns 
	 */
	logoutOnly(sdk = new Sdk): boolean {
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk}
		])
		return sdk.clearState()
	}
}

export default User
