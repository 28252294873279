export default {
	"defaults": {
		"communication": {
			"method": "get",
			"requestType": "http",
			"api_version": "v2",
			"env": {
				"local": {
					"domain": "http://local.host",
					"socketDomain": "wss://local.host"
				},
				"production": {
					"domain": "https://captainup.com",
					"socketDomain": "wss://captainup.com"
				}
			}
		}
	},
	"configProps": [
		"api_key",
		"client_token",
		"token",
		"defaults",
		"fetch_timer",
		"local",
		"local_address",
		"method",
		"requestType",
		"access_token",
		"app_data",
		"cookie",
		"hashed_id",
		"id",
		"url",
		"domain",
		"api_version",
		"protocol",
		"socketDomain"
	]
}
