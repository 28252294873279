import initialize_modules from '../modules/modules'
import Communication from '../communication/communication'

import Sdk, {InitializedSdkConfig} from '../sdk'
import utils from '../utils'
import * as _ from 'lodash'


let instance = null
/**
 * Init module manage the initialize process of the SDK, it's responsible to start async
 * recursive initialize, merge the main modules to sdk object and fetching the sdk
 * configurations.
 * @class Init
 * @tutorial Getting-Started
 * @param {Object} config - Configurations object.
 */
class Init {
	config: any

	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config: any = {}) {
		if (!instance || config.restart) {
			instance = this
			if(!config.recursive)
				config.restart = undefined

			this.config = config
		}

		return instance
	}

	/**
	 * Start recursive async process of initialize modules.
	 * First we initialize the Communication module and then we fetch app config and then all the rest SDK modules.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Function} [CommunicationModule = Communication] - Communication module.
	 * @returns {Promise<Array>} Child modules.
	 */
	up(sdk = new Sdk, CommunicationModule = Communication) {
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'CommunicationModule', type: 'Function', val: CommunicationModule}
		])

		utils.moduleInitializer<Communication>(sdk, 'communication', CommunicationModule, this.config)

		return this.getSDKConfig(true)
			// Insert the configurations we fetch for the brand to the SDK config.
			.then((res) => {
				Object.assign(this.config, sdk.config)
				Object.assign(this.config, res)
				Object.assign(sdk.config, this.config)
				return this.config
			})
			// Init the child modules using the updated config.
			.then(this.initModules)
	}

	/**
	 * Get app data.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Boolean} [forceFetch = false] - Whether to force a fetch from remote source or using cache if exist.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<InitializedSdkConfig>} App config.
	 * @example
	 * ```
	 * captain.getSDKConfig()
	 * .then((config) => console.log(config))
	 * // see example of the response in the link in the method description
	 * ```
	 */
	async getSDKConfig(forceFetch = false, sdk = new Sdk, communication = new Communication): Promise<InitializedSdkConfig> {
		utils.validateDependencies([
			{name: 'forceFetch', type: 'Boolean', val: forceFetch},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication}
		])

		// Clearing out unrelated properties from the main config so it will include only the app settings
		if (!forceFetch && !_.isEmpty(sdk.config)) {
			return Promise.resolve(Object.assign({}, sdk.config, {player: null}))
		}

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${this.config.api_key}`
		const params = {
			lang: this.config.lang,
			country: this.config.country,
			currency: this.config.currency
		}

		return communication.request(url, params)
	}

	/**
	 * Init child modules.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config = this.config] - Configurations.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {initialize_modules} [init_modules = initialize_modules] - Modules initializer
	 * @returns {Promise<Array>} Child modules.
	 */
	initModules(config = this.config, sdk = new Sdk, init_modules = initialize_modules) {
		utils.validateDependencies([
			{name: 'config', type: 'Object', val: config},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'modules', type: 'Function', val: init_modules},
		])
		
		// if (webpackGlobal.__modules__)
		// 	initModules.push(utils.moduleInitializer(sdk, 'modules', modules, config))
		return initialize_modules(sdk, config)
	}
}

export default Init
