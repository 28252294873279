import conf from './socket_config'
import * as _ from 'lodash'
import utils from '../../utils'
import BasicModule from '../../basic-module'

let instance = null

/**
 * Socket module manage the socket connections for the SDK.
 * @class Socket
 * @private
 * @param {Object} config - Configurations object.
 * @prop  {Array} methods - Expose the methods the module support.
 */
class Socket extends BasicModule implements Initiable<Socket> {
	loaded: boolean
	config: any
	methods: string[]

	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.socket, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.socket] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @returns Module is ready.
	 */
	init(config = {}, defaults = conf.defaults.socket, props = conf.configProps) {
		Object.assign(config, defaults, config)
		this.config = _.pick(config, props)
		this.methods = ['open']
		this.loaded = true
		return this
	}

	/**
	 * Open a connection via WebSocket.
	 * @version 1.0.0
	 * @private
	 * @param {String} url - Destination url.
	 * @param {Object} [params = {}] - Request params.
	 * @param {Object} [config] - Request config.
	 * @param {Function} [Socket = WebSocket] - WebSocket method.
	 * @returns {Object} WebSocket connection.
	 */
	open(url, params = {}, config = {}, Socket = WebSocket) {
		utils.validateDependencies([
			{name: 'url', type: 'String', val: url},
			{name: 'params', type: 'Object', val: params},
			{name: 'config', type: 'Object', val: config},
			{name: 'Socket', type: ['Function', 'Object'], val: Socket},
		])

		const getParams = (params) => {
			return '?' + Object.keys(params).map((val) => {
				return `${val}=${params[val]}`
			}).join('&')
		}

		url += getParams(params)
		url = url.replace('#', '%23')
		return new Socket(url)
	}
}

export default Socket
