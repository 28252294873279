import utils from '../../../utils'
import Sdk from '../../../sdk'
import conf from './currencies_config'
import * as _ from 'lodash'
import BasicModule from '../../../basic-module'
import {Subject as Rxjs_subject} from 'rxjs'

let instance = null // init the instance.
let observable = null // init the observable.

/**
 * Currencies class responsible of all badge functionality and manage the state of user currencies.
 * 
 * Part of the {@link Gaming} module.
 * @category Gaming
 */
class Currencies extends BasicModule implements Initiable<Currencies> {
	loaded: boolean
	config: any
	state: Rxjs_subject<any>


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.currencies, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} defaults=conf.defaults.currencies - Defaults object.
	 * @param {Array} props=conf.configProps - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.currencies, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!observable || config.test) {
			observable = new Rxjs_subject<any>()
			this.state = observable
		} else {
			this.state = observable
		}
		sdk.isReady().then(() => {
			// Update the badge progress.
			this.setConfig({
				currencies: sdk.config.player.currencies,
				daily_points: sdk.config.player.daily_points,
				weekly_points: sdk.config.player.weekly_points,
				monthly_points: sdk.config.player.monthly_points
			})
			// Update the state when the SDK is ready.
			this.get().then((currencies) => this.state.next(currencies)).then(() => this.loaded = true)
		})

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.badges.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.badges.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get user currencies
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<CurrenciesState>} Player currencies.
	 * @example
	 * captain.currencies.get()
	 * .then(currencies => {
	 * 	// Get current player currencies data.
	 * })
	 * @example
	 * captain.currencies.get(playerId)
	 * .then(currencies => {
	 * 	// Get specific player currencies data.
	 * })
	 */
	get(userId?, sdk = new Sdk) {
		// Check if userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		return sdk.user.get(userId).then(res => res.currencies)
	}

	/**
	 * Update user currencies state.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {CurrenciesUpdates} currenciesUpdate - Server activity.
	 * @returns {Promise<CurrenciesState>} Updated user currencies.
	 */
	update(currenciesUpdate) {
		// Check if the currenciesUpdate is valid.
		utils.validateDependencies([
			{name: 'currenciesUpdate', type: 'Object', val: currenciesUpdate}
		])
		// In case the currency is points update the view and the statistics
		const isPointsUpdated = (currenciesUpdate) => {
			if (currenciesUpdate.points) {
				const amount = Number(currenciesUpdate.points.amount_received)
				return [this.increasePoints(amount)]
			}
			return []
		}

		return Promise.all(isPointsUpdated(currenciesUpdate))
			.then(() => {
				const currencies = Object.assign({}, this.getConfig().currencies, _.mapValues(currenciesUpdate, currency => currency.player_total))
				this.setConfig({currencies})
				this.state.next(currencies)
				return currencies
			})
	}

	/**
	 * Increase the statistics and update the view.
	 * @version 1.0.0
	 * @private
	 * @param {Number} amount - Amount of points
	 * @param trigger - Implementation in the embed of event dispatcher.
	 * @returns {Object} Updated config.
	 */
	increasePoints(amount, trigger = (utils.get_environment_global_var())['captain'].trigger || utils.trigger, sdk = new Sdk) {
		// Check if the amount and trigger is valid.
		utils.validateDependencies([
			{name: 'amount', type: 'Number', val: amount},
			{name: 'trigger', type: 'Function', val: trigger}
		])

		return sdk.user.get()
			.then(({daily_points, weekly_points, monthly_points}) => this.setConfig({daily_points, weekly_points, monthly_points}))
			.then((player) => {
				trigger('player:data:add_points', amount)
				return player
			})
	}
}

export default Currencies
