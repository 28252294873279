import utils from '../../../utils'
import Communication from '../../../communication/communication'
import Sdk from '../../../sdk'
import BasicModule from '../../../basic-module'
import conf from './tournaments_config'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'

import { AssetProgress } from '../gaming-types'

let instance = null // init the instance.
let state = null // init the state observable.
let updates = null // init the updates observable.


/**
 * Find differences betweens two tournament_progress objects by tournaments progress.
 * @param {import("../gaming-types").AssetProgress} progressA
 * @param {import("../gaming-types").AssetProgress} progressB
 * @private
 */
const getProgressDiff = (progressA, progressB) => {
	// First we re-structure the tournament progress to an array of tournament ID and tournament progress (`[{key: '1asfa241x', progress: 0.5}]`)
	const newProgressMapped = _.map({...progressA, updated: {}}, ({progress}, id) => ({id, progress}))
	const oldProgressMapped = _.map({...progressB, updated: {}}, ({progress}, id) => ({id, progress}))
	return _.differenceBy(newProgressMapped, oldProgressMapped, 'progress')
		.map(tournament => tournament.id)
}

/**
 * Tournaments class responsible of all tournament functionality and manage the state of user tournaments.
 * Part of the {@link Gaming} module.
 * 
 * This is a class reference, find the tutorial here: {@link Tutorial_Tournaments}
 * @category Gaming
 * 
 */
class Tournaments extends BasicModule implements Initiable<Tournaments> {
	loaded: boolean
	config: any
	/** Expose the tournaments state(can be set to fire on every change and not only on badge completion). */
	state: Rxjs_subject<any>
	/** Expose updates in specific tournaments. */
	updates: Rxjs_subject<any>

	/**
	 * @private
	 * The cache object for registration status.
	 * It caches by user, and date, so an object may look like:
	 * {
	 *   'my_user_id': {
	 *     '2022-11-30': ['3258agf7723'... (tournament ids)]
	 *   }
	 * }
	 */
	tournamentsRegistrationStatusCache: {[key: string]: {[key: string]: string[]}} = {}


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Tournaments>|Tournaments} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.tournaments, conf.configProps, instance)
		if(init instanceof Promise)
			//@ts-ignore
			return init.then(resolvedInstance => instance = resolvedInstance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults=conf.defaults.tournaments] - Defaults object.
	 * @param {Array} [props=conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.tournaments, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = Object.assign({}, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!state || !updates || config.test) {
			state = new Rxjs_subject<any>()
			this.state = state
			updates = new Rxjs_subject<any>()
			this.updates = updates
		} else {
			this.state = state
			this.updates = updates
		}
		sdk.isReady().then(() => {
			// Update the tournament progress.
			this.config.progress = sdk.config.player.tournament_progress
			// Update the state when the SDK is ready.
			this.get().then((tournaments) => {
				this.state.next(tournaments)
			}).then(() => this.loaded = true)
		})

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.tournaments.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.tournaments.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get the registration status for current, active tournaments.
	 * Given a user (or defaults to current user in session), returns an object with the details.
	 * 
	 * In case of repeatable tournaments, it returns status of current instance.
	 * To get status of future instances (e.g a weekly tournament, the next week data) use #getUserRegistrationStatusWithTime
	 * @param tournamentId The tournament id to get the status for
	 * @param userId The user id to get the details for, if not provided, will default to current user in session
	 * @param sdk DI for SDK instance, defaults to current
	 * @returns true if registered, false otherwise
	 */
	async getUserRegistrationStatusForCurrentTournament(tournamentId: string, userId?: string, sdk = new Sdk): Promise<boolean> {
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// Assign to current if userId is unassigned
		if(!userId) {
			userId = sdk.config.player.id
		}

		return this.getUserRegistrationStatusWithTime(tournamentId, new Date(), userId)
	}

	/**
	 * Get the registration status for a tournament, on a specific date.
	 * This is used for repeatable tournaments - e.g a weekly tournament that you want the registration
	 * status of the next week instance.
	 * 
	 * @param tournamentId The tournament id to get the status for
	 * @param tournamentTime 
	 * @param userId The user id to get the details for, if not provided, will default to current user in session
	 * @param sdk DI for SDK instance, defaults to current
	 * @returns true if registered, false otherwise
	 */
	async getUserRegistrationStatusWithTime(tournamentId: string, tournamentTime: Date, userId?: string, sdk = new Sdk, communication = new Communication): Promise<boolean> {
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// Assign to current if userId is unassigned
		if(!userId) {
			userId = sdk.config.player.id
		}

		if (!this.tournamentsRegistrationStatusCache || !this.tournamentsRegistrationStatusCache[userId] || !this.tournamentsRegistrationStatusCache[userId][tournamentTime.toISOString().slice(0, 10)]) {
			await this._updateRegistrationStatusCache(tournamentTime, userId)
		}

		return this.tournamentsRegistrationStatusCache[userId][tournamentTime.toISOString().slice(0, 10)].includes(tournamentId)
	}

	/**
	 * @private
	 * @param tournamentTime 
	 * @param userId 
	 * @param sdk 
	 * @param communication 
	 * @returns 
	 */
	async _updateRegistrationStatusCache(tournamentTime: Date, userId?: string, sdk = new Sdk, communication = new Communication) {

		// Assign to current if userId is unassigned
		if(!userId) {
			userId = sdk.config.player.id
		}

		const params = {
			app_id: sdk.config.id,
			tournament_time: tournamentTime.toISOString()
		}
		const config = {
			requestType: 'http',
			method: 'post'
		} as const
		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.id}/tournaments/registration_status`
		const res = await communication.request(url, params, config)

		// If succesful, add the tournament_id to the player registered tournaments
		if (res.code === 200) {
			this.tournamentsRegistrationStatusCache[userId] ||= {}
			this.tournamentsRegistrationStatusCache[userId][tournamentTime.toISOString().slice(0, 10)] = res.registered_tournaments
			return true
		}
		return false
	}

	/**
	 * Registers a user to a tournament.
	 * If succesful, it is presented on the user `tournaments_registered` array.
	 * Return true if succesful, false otherwise.
	 * @param tournamentId the tournament id we want to register
	 * @param tournamentTime the tournament date to act on. Useful with repeatable tournaments.
	 * If a tournament is weekly repeatable, pass any date in next week to register for next week's tournament.
	 * 
	 * Can only be in the future, can't be more than 60 days into the future.
	 * @param sdk 
	 * @param communication 
	 */
	async register(tournamentId: string, tournamentTime = new Date(), sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'tournamentId', type: 'String', val: tournamentId},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication}
		])

		const params = {
			app_id: sdk.config.id,
			tournament_time: tournamentTime.toISOString()
		}
		const config = {
			requestType: 'http',
			method: 'post'
		} as const
		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.id}/tournaments/${tournamentId}/register`
		const res = await communication.request(url, params, config)

		// If succesful, add the tournament_id to the player registered tournaments
		if (res.code === 200) {
			await this._updateRegistrationStatusCache(tournamentTime)
			return true
		}
		return false
	}

	/**
	 * Unregisters a user to a tournament.
	 * WARN: unregistering a user will delete all his progress.
	 * If succesful, it is presented on the user `tournaments_registered` array.
	 * Return true if succesful, false otherwise.
	 * @param tournamentId the tournament id we want to register
	 * @param tournamentTime the tournament date to act on. Useful with repeatable tournaments.
	 * If a tournament is weekly repeatable, pass any date in next week to unregister from next week's tournament.
	 * 
	 * Can only be in the future, can't be more than 60 days into the future.
	 * @param sdk 
	 * @param communication 
	 */
	async unregister(tournamentId: string, tournamentTime = new Date(), sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'tournamentId', type: 'String', val: tournamentId},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication}
		])

		const params = {
			app_id: sdk.config.id,
			tournament_time: tournamentTime.toISOString()
		}
		const config = {
			requestType: 'http',
			method: 'post'
		} as const
		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.id}/tournaments/${tournamentId}/unregister`
		const res = await communication.request(url, params, config)

		// If succesful, remove the tournament_id to the player registered tournaments
		if (res.code === 200) {
			await this._updateRegistrationStatusCache(tournamentTime)
			return true
		}
		return false
	}

	/**
	 * Get sorted list of all tournaments and their progress.
	 * We filter out unfinished tournaments of another player from the list.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {import("../gaming-types").AssetSetting=} tournaments_settings - Tournaments settings data.
	 * @param {AssetProgress=} tournament_progress - Player `tournament_progress` data.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<Tournament>>} Tournaments list
	 * @example
	 * captain.tournaments.get()
	 * .then(tournaments => {
	 * // Get current player tournaments data with progress.
	 * })
	 * @example
	 * captain.tournaments.get(playerId)
	 * .then(tournaments => {
	 * 	// Get specific player tournaments data with progress.
	 * })
	 */
	get(userId?: string, tournaments_settings?, tournament_progress?: AssetProgress, sdk = new Sdk) {
		// Check if the userId, tournament_progress and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'tournaments_settings', type: ['Array', 'Undefined'], val: tournaments_settings},
			{name: 'tournament_progress', type: ['Object', 'Undefined'], val: tournament_progress},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// Assign to current if userId is unassigned
		if(!userId) {
			userId = sdk.config.player.id
		}

		// Get list of tournament settings and tournament progress.
		return Promise.all([
			...(tournaments_settings? [tournaments_settings] : [this.getList()]),
			...(tournament_progress? [tournament_progress] : [this.getProgress(userId)])
		])
		.then(([listResolve, progressResolve]) => {
			// Update the static config data with the resolved lists.
			this.setConfig({progress: progressResolve, tournaments: listResolve})
			// Merge the lists.
			let tournaments = this.attachPlayerData(listResolve, progressResolve, sdk.config.player.unavailable_segmented_tournaments)

			return tournaments
		})
	}


	/**
	 * Merge the tournament settings with the specific player data(like tournament progress and segmentation).
	 * @version 1.0.0
	 * @private
	 * @param {Array<TournamentSettings>} listResolve - Tournament setting list.
	 * @param {AssetProgress} progressResolve - Tournament progress list.
	 * @param {Array} unavailable_segmented_tournaments - Tournament segmentation list.
	 * @returns {Array<Tournament>} Tournaments list with progress merged to settings.
	 */
	attachPlayerData(listResolve, progressResolve: AssetProgress, unavailable_segmented_tournaments) {
		// Check if listResolve, progressResolve and unavailable_segmented_tournaments is valid.
		utils.validateDependencies([
			{name: 'listResolve', type: 'Array', val: listResolve},
			{name: 'progressResolve', type: 'Object', val: progressResolve},
			{name: 'unavailable_segmented_tournaments', type: 'Array', val: unavailable_segmented_tournaments}
		])
		// Iterate over tournament progress list
		return _.map(progressResolve, (tournamentProgress, tournamentId) => {
			// For each progress find the tournament setting.
			const tournamentList = listResolve.find((tournamentItem) => (tournamentItem._id === tournamentId))
			// If settings can't be reach -> return false.
			if(!tournamentList)
				return false
			// Add player data to settings
			return {...tournamentList, progress: tournamentProgress, is_segmented_for_user: !unavailable_segmented_tournaments.includes(tournamentList.id)}
		})
		// Filter the 'false' elements.
		.filter((tournament) => tournament)
	}


	/**
	 * Get list of tournament settings
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<TournamentSettings>>} Tournament setting list instance.
	 * @example
	 * captain.tournaments.getList()
	 * .then(tournaments => {
	 * 	// Get app all tournaments settings.
	 * })
	 */
	async getList(sdk = new Sdk) {
		// Check if sdk is valid.
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk}
		])
		let app_settings = await sdk.init.getSDKConfig()
		return app_settings.tournaments || []
	}

	/**
	 * Get list of tournament progress
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User Id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns Tournament progress list instance.
	 * @example
	 * ```
	 * captain.tournaments.getProgress()
	 * .then(progress => {
	 * 	// Get player tournament progress.
	 * })
	 * ```
	 */
	async getProgress(userId, sdk = new Sdk): Promise<AssetProgress[]> {
		// Check if userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		const user_details = await sdk.user.get(userId)
		return user_details.tournament_progress
	}

	/**
	 * Get tournament with setting and progress by id.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} id - Tournament Id.
	 * @returns {Promise<Tournament>} Specific tournament.
	 * @example
	 * captain.tournaments.getTournament('*specificTournamentId*')
	 * .then(tournament => {
	 * 	// Get app specific tournament settings with progress.
	 * })
	 */
	getTournament(id) {
		// Check if id is valid.
		utils.validateDependencies([
			{name: 'id', type: 'String', val: id}
		])
		return this.get().then((tournaments) => tournaments.find((tournament) => (tournament._id === id)))
	}

	/**
	 * Save the updated calculate tournament list and push the update to observable.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Tournament=} tournament - Tournament object.
	 * @param {Object<AssetProgress>=} tournament_progress - Updated player `tournament_progress` data.
	 * @returns {Promise<Array<Tournament>>} Updated tournament list.
	 */
	update(tournament, tournament_progress) {
		// Check if tournament and tournament_progress is valid.
		utils.validateDependencies([
			{name: 'tournament', type: ['Object', 'Undefined'], val: tournament},
			{name: 'tournament_progress', type: ['Object', 'Undefined'], val: tournament_progress}
		])

		// This method handle 3 cases:
		// 1. Player achieved tournament.
		// 2. Player progress changed but he didn't achieved tournament.
		// 3. Player progress didn't changed and he didn't achieved tournament.

		let getDependencies = [undefined, undefined, tournament_progress]
		let diffs = []
		// Get latest tournaments settings.
		const {tournaments: tournaments_old, progress: tournament_progress_old, sensitive_tournament_state} = this.getConfig()

		// Shallow check if some data was changed in the `tournament_progress`
		if (sensitive_tournament_state)
			diffs = getProgressDiff(tournament_progress, tournament_progress_old)

		// If 'tournament' is falsy it means that we in `sensitive_tournament_state` mode and we didn't achieved any tournament.
		if (!tournament) {
			if (_.isEmpty(diffs))
				return this.get(undefined, tournaments_old, tournament_progress)

			getDependencies = [undefined, tournaments_old, tournament_progress]
		}

		return this.get(...getDependencies)
			.then(tournaments => {
				this.setConfig({progress: tournament_progress, tournaments})
				this.state.next(tournaments)
				// Trigger `updates` events for any tournament progress that was changed.
				tournaments.forEach(innerTournament => {
					if (sensitive_tournament_state && innerTournament.id !== (tournament || {}).id && diffs.includes(innerTournament.id))
						this.updates.next(innerTournament)
				})

				if (tournament)
					this.updates.next(tournament)

				return tournaments
			})
	}

	/**
	 * Process activity to tournament structure.
	 * @version 1.0.0
	 * @private
	 * @param {Tournament} tournament - Tournament settings and progress.
	 * @param l - Locale data.
	 * @returns {Object} Structured params.
	 */
	processActivity(tournament, l = (utils.get_environment_global_var())['captain'].l || {}) {
		// Check if tournament and l is valid.
		utils.validateDependencies([
			{name: 'tournament', type: 'Object', val: tournament},
			{name: 'l', type: 'Object', val: l},
		])

		const params = {
			type: 'tournament',
			points: tournament.points,
			data: {
				name: tournament.name,
				image: tournament.preset_image,
				item_id: tournament._id,
				tournament,
				l
			}
		}
		return params
	}


}

export default Tournaments