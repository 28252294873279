export default {
	"defaults": {
		"currencies": {}
	},
	"configProps": [
		"currencies",
		"daily_points",
		"weekly_points",
		"monthly_points"
	]
}