import Sdk from './sdk'
import utils from './utils'
(() => {
	if (typeof (utils.get_environment_global_var())['captain'] === 'undefined') {
		// Assign the sdk to global object.
		(utils.get_environment_global_var())['captain'] = new Sdk
	} else {
		// Assign the sdk into the existing embed.
		(utils.get_environment_global_var())['captain'].sdk = new Sdk
	}
})()
