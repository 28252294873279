export default {
	"defaults": {
		"badges": {
			sensitive_badge_state: false
		}
	},
	"configProps": [
		"access_token",
		"achieved_badges",
		"action_counter",
		"activities",
		"all_time_position",
		"api_key",
		"app_data",
		"app_specific_id",
		"badge_progress",
		"badges",
		"client_token",
		"cookie",
		"current_missions",
		"daily_points",
		"daily_position",
		"debug",
		"defaults",
		"full_profile",
		"global_display_name",
		"global_first_name",
		"global_last_name",
		"global_name",
		"hashed_id",
		"id",
		"inbox_activities",
		"is_anonymous",
		"last_activity",
		"level",
		"local",
		"local_address",
		"member_since",
		"monthly_points",
		"monthly_position",
		"name",
		"next_level",
		"next_mission",
		"points",
		"private_activities",
		"quiet_mode",
		"signed_user",
		"total_actions",
		"total_daily_actions",
		"total_monthly_actions",
		"total_weekly_actions",
		"unread_activities",
		"unread_inbox",
		"user",
		"is_blocked",
		"weekly_points",
		"weekly_position",
		"sensitive_badge_state",
		"scheduled",
		"active_from",
		"active_from_date",
		"disabled_from",
		"disabled_from_date"
	]
}
