export default {
	"defaults": {
		"actions": {

		}
	},
	"configProps": [
		"action_settings",
		"total_actions",
		"total_daily_actions",
		"total_monthly_actions",
		"total_weekly_actions"
	]
}