import mustache from 'mustache'

const get_mustache_context = (player, element_type, element_details) => {
  const context = {
    player
  }

  // Enrich the player level with the data.
  // by default, `player.level` is the id of the level, not the data itself.
  if (context.player && context.player.level && typeof context.player.level === 'string') {
    context.player.level = window.captain.config.levels.find(level => level._id === context.player.level)
  }

  // Add the element settings to the context
  context[element_type] = element_details

  return context
}

const render_template = (template_string, mustache_context) => {
  try {
    const rendered_template = mustache.render(template_string, mustache_context)
    return rendered_template
  } catch {
    return template_string
  }
}

export {
  get_mustache_context,
  render_template
}